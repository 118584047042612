import React from "react";
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "../layouts/auth";
import AdminLayout from "../layouts/admin";
// import RTLLayout from "../layouts/rtl";
import SpinLoading from "../components/spinLoading"
import ModalRoot from "../components/modal/index"

import { useSelector as Check } from '../hooks/useTypedSelector'

const index = () => {
  const { posts } = Check((state) => state)

  return (
    <div>
      {/* Modal Root */}
      <ModalRoot/>

      {/* Loading Root */}
      <SpinLoading isRequest={posts.loading}/>

      <BrowserRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          {/* <Route path={`/rtl`} component={RTLLayout} /> */}
          <Redirect from="/" to="/admin" />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default index;
