import {
	Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Tooltip, TableContainer, Tfoot, TableCaption,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Button,
	Select,
	Badge,

	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,

	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	InputGroup,
	InputLeftElement,
	Box
} from '@chakra-ui/react';

import { useDispatch } from 'react-redux'
import React, { useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { Icon } from '@chakra-ui/react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdSearch } from 'react-icons/md';

// Custom components
import Card from 'components/card/Card';
import Menu from './MainMenuProduct';
import { formatDate, formatMoney } from '../../../../utils/commonLib'

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getPostsUpdateCategory } from 'store/client/actions/Thunk';

export default function ColumnsTable(props: { [x: string]: any, columnsData: any; tableData: any; title: string; onClickDetail: (id: any) => void; reload: any, listCategory: any, filterProductCategory: any }) {
	const { columnsData, tableData, title, onClickDetail, reload, listCategory, filterProductCategory } = props;

	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);

	const tableInstance = useTable(
		{
			columns,
			data
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, pageOptions, setPageSize, headerGroups, page, prepareRow, initialState, gotoPage, canPreviousPage, previousPage, nextPage, canNextPage, pageCount, state: { pageIndex, pageSize } } = tableInstance;
	// initialState.pageSize = 100;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const styleSelect = {
		padding: "5px",
		border: "1px solid #80808082",
		borderRadius: '10px'

	}

	const [initialValues, setInitialValues] = useState({
		name: '',
		description: '',
		category_id: '',
	})
	const dispatch = useDispatch()

	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();

	const min = 'Ít nhất 3 kí tự.'
	const max = 'Giới hạn 50 kí tự.'
	const required = 'Trường thông tin bắt buộc.'
	const addNewCustomerSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, min)
			.max(50, max)
			.required(required),
		description: Yup.string()
			.min(2, min)
			.max(100, max)
			.required(required)
	});

	const handleUpdateCategory = (detailCategory: any) => {

		const detailObj = detailCategory.row.original
		const newObj = {
			name: detailObj.name,
			description: detailObj.description,
			category_id: detailObj._id
		}
		setInitialValues({ ...newObj })
		onOpen1()
	}

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='2' justify='space-between' mb='2' align='center'>
				{/* <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					{title ? title : 'Danh sách'}
				</Text> */}
				<Flex>
					<InputGroup>
						<InputLeftElement
							pointerEvents='none'
							children={<MdSearch color='gray.300' />}
						/>
						<Input type='tel' placeholder='Tìm theo tên phân loại dịch vụ' onChange={(e) => filterProductCategory(e)} />
					</InputGroup>
				</Flex>
				<Menu reload={reload} listCategory={listCategory} />
			</Flex>
			<Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe='10px'
									px={2}
									py={4}
									key={index}
									borderColor={borderColor}>
									<Flex
										justify='space-between'
										align='center'
										fontSize={{ sm: '10px', lg: '12px' }}
									>
										{column.render('Header')}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						return (
							<Tr {...row.getRowProps()} key={index}>
								{row.cells.map((cell, index) => {

									if (cell.column.Header == 'Tên') {
										return (
											<Td
												{...cell.getCellProps()}
												key={index}
												px={2}
												py={4}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'
												onClick={() => handleUpdateCategory(cell)}
												style={{ cursor: 'pointer' }}
											>
												<Tooltip label='Chỉnh sửa' bg='green'>
													<Badge variant='outline' colorScheme='green'>
														{/* <Text color={textColor} fontSize='sm' fontWeight='700'> */}
														{cell.value}
														{/* </Text> */}
													</Badge>
												</Tooltip>
											</Td>
										);
									}
									if (cell.column.Header == 'Ngày' || cell.column.Header == 'Ngày tạo' || cell.column.Header == 'Ngày chỉnh sửa') {
										cell.value = formatDate(cell.value)
									}
									if (cell.column.Header == 'Giá') {
										cell.value = formatMoney(cell.value, 0)
									}

									if (cell.column.Header == 'Loại sản phẩm') {
										return <Td
											{...cell.getCellProps()}
											key={index}
											px={2}
											py={4}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'
										>
											<Badge variant='outline' colorScheme='green'>
												{cell.value}
											</Badge>
										</Td>
									}

									if (cell.column.Header == 'Loại dịch vụ') {
										return <Td
											{...cell.getCellProps()}
											key={index}
											px={2}
											py={4}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'
										>
											<Badge variant='outline' colorScheme='purple'>
												{cell.value}
											</Badge>
										</Td>
									}
									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											px={2}
											py={4}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'
										>
											<Text color={textColor} fontSize='sm' >
												{cell.value}
											</Text>
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Flex justify='space-between' align='center' px='37px'>
				<Flex>
					{/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						{"<<"}
					</button>{" "} */}
					<Button colorScheme='teal' size='small' onClick={() => previousPage()} disabled={!canPreviousPage}>
						<Icon as={MdKeyboardArrowLeft} width='30px' height='30px' color='inherit' />
					</Button>&ensp;
					<span style={{ padding: '3px 5px 0 0' }}>
						<strong>
							{pageIndex + 1} / {pageOptions.length}
						</strong>{" "}
					</span>
					<Button colorScheme='teal' size='small' onClick={() => nextPage()} disabled={!canNextPage}>
						<Icon as={MdKeyboardArrowRight} width='30px' height='30px' color='inherit' />
					</Button>&ensp;
					{/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						{">>"}
					</button>{" "} */}
					{/* <span style={{ padding: '3px 5px 0 0' }}>
						<Flex>
						<div>
						| Đi đến trang:&ensp;
						</div>

						<span><NumberInput size='xs' maxW={16} defaultValue={pageIndex + 1} min={1} max={pageOptions.length}>
							<NumberInputField onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0;
								gotoPage(page);
							}} />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput></span>
						</Flex>
					</span>{" "} */}
				</Flex>


				<select
					style={styleSelect}
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
				>
					{[5, 10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							{pageSize}
						</option>
					))}
				</select>
			</Flex>
			<Modal closeOnOverlayClick={false} isOpen={isOpen1} onClose={onClose1} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Chỉnh sửa thông tin loại sản phẩm</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Formik
							initialValues={initialValues}
							validationSchema={addNewCustomerSchema}
							onSubmit={async values => {
								onClose1()
								const newValue: any = { ...values }
								const tokenLocal = localStorage.getItem("LoginInfo")
								if (tokenLocal) {
									let id = ''
									const tokenParse = JSON.parse(tokenLocal)
									id = tokenParse.data[0]._id
									newValue.updated_userid = id
									newValue.category_id = initialValues.category_id
									const result: any = await dispatch(getPostsUpdateCategory(newValue))
									if (result && result.status === "SUCCESS") {
										reload()
									}
								}
							}}
						>
							{({ errors, touched }) => (
								<Form>
									<Field name="name">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.name && touched.name}>
												<FormLabel>Tên sản phẩm</FormLabel>
												<Input {...field} placeholder='Tên sản phẩm' />
												<FormErrorMessage>{errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<Field name="description">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.description && touched.description}>
												<FormLabel>Mô tả chi tiết</FormLabel>
												<Input {...field} placeholder='Mô tả chi tiết' />
												<FormErrorMessage>{errors.description}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<div style={{ textAlign: 'right' }}>
										<div>
											<Button
												mt={4}
												mr={3}
												colorScheme='blue'
												isLoading={props.isSubmitting}
												type='submit'
											>
												Cập nhật
											</Button>
											<Button
												mt={4}
												colorScheme='blue'
												variant='outline'
												onClick={onClose1}
											>
												Thoát
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Card>
	);
}
