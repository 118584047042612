import {
	Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Tooltip, TableContainer, Tfoot, TableCaption,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Button,
	Select,
	Badge,

	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,

	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	SimpleGrid,
	Center,
	Code,
	InputGroup,
	InputLeftElement,
	Box
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux'
import React, { useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Icon } from '@chakra-ui/react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdSearch } from 'react-icons/md';

// Custom components
import Card from 'components/card/Card';
import Menu from './MainMenuProduct';
import { formatDate, formatMoney } from '../../../../utils/commonLib'
import { getPostsUpdateProduct } from 'store/client/actions/Thunk';
export default function ColumnsTable(props: { [x: string]: any, columnsData: any; tableData: any; title: string; reload: any, listCategory: any, filterService: any }) {
	const { columnsData, tableData, title, reload, listCategory, filterService } = props;

	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);

	const tableInstance = useTable(
		{
			columns,
			data
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, pageOptions, setPageSize, headerGroups, page, prepareRow, initialState, gotoPage, canPreviousPage, previousPage, nextPage, canNextPage, pageCount, state: { pageIndex, pageSize } } = tableInstance;
	// initialState.pageSize = 100;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const styleSelect = {
		padding: "5px",
		border: "1px solid #80808082",
		borderRadius: '10px'

	}

	const [showCost, setShowCost] = useState("")
	const [showRealCost, setRealShowCost] = useState("")

	const [initialValues, setInitialValues] = useState({
		name: '',
		type: '',
		detailed_description: '',
		cost: '',
		real_cost: '',
		product_id: ''
	})
	const dispatch = useDispatch()

	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();

	const min = 'Ít nhất 3 kí tự.'
	const max = 'Giới hạn 50 kí tự.'
	const required = 'Trường thông tin bắt buộc.'
	const addNewCustomerSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, min)
			.max(50, max)
			.required(required),
		detailed_description: Yup.string()
			.min(2, min)
			.max(100, max)
			.required(required),
		cost: Yup.number()
			.typeError('Vui lòng nhập số.')
			.min(100, "Số tiền ít nhất là 100đ")
			.required(required),
		real_cost: Yup.number()
			.typeError('Vui lòng nhập số.')
			.min(100, "Số tiền ít nhất là 100đ")
			.required(required),
		type: Yup.string()
			.required(required),
	});

	const handleUpdateProduct = (detailProduct: any) => {
		const detailObj = detailProduct.row.original
		const newObj = {
			name: detailObj.name,
			type: detailObj.category_id && detailObj.category_id.name,
			detailed_description: detailObj.detailed_description,
			cost: detailObj.cost,
			real_cost: detailObj?.real_cost || 0,
			product_id: detailObj._id
		}
		setInitialValues({ ...newObj })
		onOpen1()
	}

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='2' justify='space-between' mb='2' align='center'>
				{/* <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					{title ? title : 'Danh sách'}
				</Text> */}
				<Flex>
					<InputGroup>
						<InputLeftElement
							pointerEvents='none'
							children={<MdSearch color='gray.300' />}
						/>
						<Input type='tel' placeholder='Tìm theo tên dịch vụ' onChange={(e) => filterService(e)} />
					</InputGroup>
				</Flex>
				<Menu reload={reload} listCategory={listCategory} />
			</Flex>
			<Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe='10px'
									py={4}
									px={2}
									key={index}
									borderColor={borderColor}>
									<Flex
										justify='space-between'
										align='center'
										fontSize={{ sm: '10px', lg: '12px' }}
									>
										{column.render('Header')}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						return (
							<Tr {...row.getRowProps()} key={index}>
								{row.cells.map((cell, index) => {

									if (cell.column.Header == 'Tên') {
										return (
											<Td
												{...cell.getCellProps()}
												key={index}
												py={4}
												px={2}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'
												onClick={() => handleUpdateProduct(cell)}
												style={{ cursor: 'pointer' }}
											>
												<Tooltip label='Chỉnh sửa' bg='blue'>
													<Text color={textColor} fontSize='sm' fontWeight='700'>
														{cell.value}
													</Text>
												</Tooltip>
											</Td>
										);
									}

									if (cell.column.Header == 'Ngày' || cell.column.Header == 'Ngày tạo' || cell.column.Header == 'Ngày chỉnh sửa') {
										cell.value = formatDate(cell.value)
									}
									if (cell.column.Header == 'Giá') {
										cell.value = formatMoney(cell.value, 0) + " VND"
									}

									if (cell.column.Header == 'Loại dịch vụ') {
										return <Td
											{...cell.getCellProps()}
											key={index}
											py={4}
											px={2}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'
										>
											<Badge variant='outline' colorScheme='purple'>
												{cell.value}
											</Badge>
										</Td>
									}
									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											py={4}
											px={2}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'
										>
											<Text color={textColor} fontSize='sm'>
												{cell.value}
											</Text>
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Flex justify='space-between' align='center' px='37px'>
				<Flex>
					{/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						{"<<"}
					</button>{" "} */}
					<Button colorScheme='teal' size='small' onClick={() => previousPage()} disabled={!canPreviousPage}>
						<Icon as={MdKeyboardArrowLeft} width='30px' height='30px' color='inherit' />
					</Button>&ensp;
					<span style={{ padding: '3px 5px 0 0' }}>
						<strong>
							{pageIndex + 1} / {pageOptions.length}
						</strong>{" "}
					</span>
					<Button colorScheme='teal' size='small' onClick={() => nextPage()} disabled={!canNextPage}>
						<Icon as={MdKeyboardArrowRight} width='30px' height='30px' color='inherit' />
					</Button>&ensp;
					{/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						{">>"}
					</button>{" "} */}
					{/* <span style={{ padding: '3px 5px 0 0' }}>
						<Flex>
							<div>
								| Đi đến trang:&ensp;
							</div>

							<span><NumberInput size='xs' maxW={16} defaultValue={pageIndex + 1} min={1} max={pageOptions.length}>
								<NumberInputField onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									gotoPage(page);
								}} />
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput></span>
						</Flex>
					</span>{" "} */}
				</Flex>


				<select
					style={styleSelect}
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
				>
					{[5, 10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							{pageSize}
						</option>
					))}
				</select>
			</Flex>

			<Modal closeOnOverlayClick={false} isOpen={isOpen1} onClose={onClose1} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Chỉnh sửa thông tin dịch vụ</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Formik
							initialValues={initialValues}
							validationSchema={addNewCustomerSchema}
							onSubmit={async values => {
								onClose1()
								const newValue: any = { ...values }
								const tokenLocal = localStorage.getItem("LoginInfo")
								if (tokenLocal) {
									let id = ''
									const tokenParse = JSON.parse(tokenLocal)
									id = tokenParse.data[0]._id
									newValue.updated_userid = id
									newValue.product_id = initialValues.product_id
									const result: any = await dispatch(getPostsUpdateProduct(newValue))
									if (result && result.status === "SUCCESS") {
										reload()
									}
								}
							}}
						>
							{({ errors, touched }) => (
								<Form>
									<Field name="name">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.name && touched.name}>
												<FormLabel>Tên sản phẩm</FormLabel>
												<Input {...field} placeholder='Tên sản phẩm' />
												<FormErrorMessage>{errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<Field name="type">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.type && touched.type}>
												<FormLabel>Loại sản phẩm</FormLabel>
												<Select {...field} placeholder='Loại sản phẩm'>
													{listCategory && listCategory.length > 0 ?
														listCategory.map((item: any, index: any) => <option key={index} value={item.name}>{item.name}</option>)
														: ""
													}
												</Select>
												<FormErrorMessage>{errors.type}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<Field name="detailed_description">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.detailed_description && touched.detailed_description}>
												<FormLabel>Mô tả chi tiết</FormLabel>
												<Input {...field} placeholder='Mô tả chi tiết' />
												<FormErrorMessage>{errors.detailed_description}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<SimpleGrid columns={2}>
										<Field name="cost">
											{({ field, form }: any) => (
												<FormControl isInvalid={errors.cost && touched.cost}>
													<FormLabel>Giá</FormLabel>
													<Input {...field} placeholder='Giá' onKeyUp={(e: any) => setShowCost(e.target.value)} />
													<FormErrorMessage>{errors.cost}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<Flex direction={'column'} justifyContent={'flex-end'} pb={5}>
											<Box><Code bg="yellow">{showCost && showCost != "" ? formatMoney(showCost, 0) : formatMoney(initialValues.cost, 0)} VND</Code></Box>
										</Flex>d
									</SimpleGrid><br />
									<SimpleGrid columns={2}>
										<Field name="real_cost">
											{({ field, form }: any) => (
												<FormControl isInvalid={errors.real_cost && touched.real_cost}>
													<FormLabel>Thực giá</FormLabel>
													<Input {...field} placeholder='Thực giá' onKeyUp={(e: any) => setRealShowCost(e.target.value)} />
													<FormErrorMessage>{errors.real_cost}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<Flex direction={'column'} justifyContent={'flex-end'} pb={5}>
											<Box><Code bg="yellow">{showRealCost && showRealCost != "" ? formatMoney(showRealCost, 0) : formatMoney(initialValues.real_cost, 0)} VND</Code></Box>
										</Flex>
									</SimpleGrid>
									<div style={{ textAlign: 'right' }}>
										<div>
											<Button
												mt={4}
												mr={3}
												colorScheme='blue'
												isLoading={props.isSubmitting}
												type='submit'
											>
												Cập nhật
											</Button>
											<Button
												mt={4}
												colorScheme='blue'
												variant='outline'
												onClick={onClose1}
											>
												Thoát
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Card>
	);
}
