import axios, { AxiosRequestConfig } from "axios";

interface MyAxiosRequestConfig extends Omit<AxiosRequestConfig, "headers"> {
	headers?: any; // this was "any" at v0.21.1 but now broken between 0.21.4 >= 0.27.2
        // Lets make it any again to make it work again.
}

const requestAPI = () => {
	const { REACT_APP_API_HOST, REACT_APP_SECRET_KEY } = process.env;

	const axiosInstance = axios.create({
		baseURL: REACT_APP_API_HOST,
		// baseURL: 'http://localhost:9500/',
		timeout: 15000,
		responseType: "json"
	});

	const tokenLocal = localStorage.getItem("LoginInfo")
	if(tokenLocal){
		let token = ''
		const tokenParse = JSON.parse(tokenLocal)
		token = tokenParse.token

		// Set the AUTH token for any request
		axiosInstance.interceptors.request.use(function (config: any) {
			config.headers[REACT_APP_SECRET_KEY] =  token 
			return config;
		});
	}

	return axiosInstance;
};

export default requestAPI;