export const columnsDataBill = [
    // {
    //   Header: "Mã hoá đơn",
    //   accessor: "_id",
    // },
    {
      Header: "Khách hàng",
      accessor: "customer_username",
    },
    {
      Header: "Ngày tạo",
      accessor: "create_date",
    },
    {
      Header: "Giới tính",
      accessor: "customer_sex",
    },
    {
      Header: "Số điện thoại",
      accessor: "customer_phone_number",
    },
    {
      Header: "Mô tả",
      accessor: "description",
    },
    {
      Header: "Tổng tiền",
      accessor: "total",
    },
    {
      Header: "Tình trạng",
      accessor: "isPaid",
    },
    {
      Header: "Ghi chú",
      accessor: "noteWhenNotPaid",
    },
    {
      Header: "Nhân viên tạo",
      accessor: "created_username",
    },
];

export const columnsDataColumnsBill = [
  {
    Header: "Tên",
    accessor: "customer_username",
  },
  {
    Header: "Ngày tạo",
    accessor: "create_date",
    tyep: "date"
  },
  {
    Header: "Người tạo",
    accessor: "created_username",
  },
];