import {
	Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Tooltip, Tfoot,
	Button,

	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Code,

	Tabs, TabList, TabPanels, Tab, TabPanel,

	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Select,
	Box,
	SimpleGrid,
	Center,
	IconButton,


	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,

	Badge,

	Popover,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor,

	useToast,
	Textarea,
	useBoolean
} from '@chakra-ui/react';
import { PopoverTrigger as OrigPopoverTrigger } from '@chakra-ui/react'
import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { PDFExport } from "@progress/kendo-react-pdf";

import { Icon } from '@chakra-ui/react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdOutlineEditCalendar, MdCheckCircle, MdCancel, MdOutlineError, MdOutlinePersonOutline, MdNotes, MdAttachMoney, MdPhoneIphone, MdMale, MdFemale, MdOutlinePersonPin, MdOutlineQrCode2, MdDoneOutline, MdCalendarToday, MdPrint } from 'react-icons/md';
import { getPostListCutomer, getPostListProductService, getPostsCreateBill } from "../../../store/client/actions/Thunk"

import { useDispatch } from 'react-redux'
import { columnsDataBill, columnsDataColumnsBill } from "./constant";
import logo5 from "assets/img/logo/logobill5.png";
import logobill from "assets/img/logo/logobillnew.png";
// import logobil_trangden from "assets/img/logo/logobil_trangden.png";
import { toPng } from 'html-to-image';
// Custom components
import Card from 'components/card/Card';
// import Menu from './MainMenu';
import { formatDate, formatMoney } from '../../../utils/commonLib'
// import { getPostsUpdateCustomer } from 'store/client/actions/Thunk';
import { Employee, PostBill } from 'store/client/actions/Model';
import { getPostsUpdateIsPaidBill } from 'store/client/actions/Thunk';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ColumnsTableNotPaid from './ColumnsTableNotPaid';

const discountOption: any = [
	0, 5, 8, 10, 13, 15,
	17, 20, 23, 25, 27,
	30, 33, 35, 37, 40,
	43, 45
]
// for (let i = 0; i <= 100; i = i + 5) {
// 	discountOption.push(i)
// }

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger

export default function ColumnsTable(props: { [x: string]: any, columnsData: any; tableData: any; title: string; onClickDetail: (id: any) => void; reload: any }) {

	const toast = useToast()
	const { columnsData, tableData, title, onClickDetail, reload, listUserIsNotPaid } = props;

	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { pageSize: 20 }
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, pageOptions, setPageSize, headerGroups, page, prepareRow, initialState, gotoPage, canPreviousPage, previousPage, nextPage, canNextPage, pageCount, state: { pageIndex, pageSize } } = tableInstance;
	// initialState.pageSize = 100;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const [detail, setDetail] = useState(null)
	const reportTemplateRef = useRef(null);
	const ref = useRef<HTMLDivElement>(null)

	const onButtonClick = useCallback((name: any) => {
		if (ref.current === null) {
			return
		}

		toPng(ref.current, { cacheBust: true, })
			.then((dataUrl) => {
				const link = document.createElement('a')
				link.download = name
				link.href = dataUrl
				link.click()
			})
			.catch((err) => {
				console.log(err)
			})
	}, [ref])

	const [listProduct, setListProduct] = useState(null)
	const [listProductFilter, setListProductFilter] = useState(null)

	const [listService, setListService] = useState(null)
	const [listServiceFilter, setListServiceFilter] = useState(null)

	const [listCustomer, setListCustomer] = useState(null)
	const [listCustomerFilter, setListCustomerFilter] = useState(null)

	const [customerTxt, setCustomerTxt] = useState("")
	const [customer, setCustomer] = useState(null)

	const [listProductChoose, setListProductChoose] = useState([])

	const [bill, setBill] = useState({
		description: '',
		isPaid: -1,
		noteWhenNotPaid: '',
	});

	const [total, setTotal] = useState(null)

	const styleSelect = {
		padding: "5px",
		border: "1px solid #80808082",
		borderRadius: '10px'
	}

	useEffect(() => {
		getDataProductService()
	}, [])

	const getDataCustomer = async () => {
		const result: any = await dispatch(getPostListCutomer())
		if (result && result.status == "SUCCESS") {
			const data = result.data.map((item: any, index: number) => {
				if (item.sex == true) {
					item.sex = "Nữ"
				} else {
					item.sex = "Nam"
				}
				return item
			})
			setListCustomer(data)
		}
	};


	const getDataProductService = async () => {
		const result: any = await dispatch(getPostListProductService())
		if (result && result.status == "SUCCESS") {
			const data = result.data.map((item: any, index: number) => {
				if (item.category_id && item.category_id.name) {
					item.category_name = item.category_id.name
				}
				return item
			})

			const dataProduct = data.filter((item: any, index: any) => item.is_service == false)
			setListProduct(dataProduct)

			const dataService = data.filter((item: any, index: any) => item.is_service == true)
			setListService(dataService)
			getDataCustomer()
		}
	};

	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
	const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

	const dispatch = useDispatch();

	const showDetailBill = (objectDetailBill: any) => {
		setDetail(objectDetailBill.row.original)
		onOpen1()
	}

	const filterProduct = (e: any) => {
		if (e.target.value && e.target.value != "") {
			return setListProductFilter(listProduct.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
		}
		setListProductFilter([])
	}

	const filterService = (e: any) => {
		if (e.target.value && e.target.value != "") {
			return setListServiceFilter(listService.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
		}
		setListServiceFilter([])
	}

	const filterCustomer = (e: any) => {
		setCustomerTxt(e.target.value)
		if (e.target.value && e.target.value != "") {
			return setListCustomerFilter(listCustomer.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
		}
		setListCustomerFilter([])
	}

	const handleOnChange = (event: any) => {

		try {
			setBill(bill => ({ ...bill, [event.target.name]: event.target.value }));
		} catch (error) {
			alert(error)
			console.log(error)
		}

	};

	const handleIncrementProductABC = async (value: any, objProduct: any) => {
		const lstProductChoose = [...listProductChoose]

		const newProduct = {
			service_id: objProduct._id,
			service_name: objProduct.name,
			amount: 1,
			quantity: objProduct.quantity, 
			cost: objProduct.cost,
			real_cost: objProduct?.real_cost || 0,
			discount: 0,
			total: objProduct.cost
		}

		if (lstProductChoose && lstProductChoose.length > 0) {
			const index = lstProductChoose.findIndex((item: any) => item.service_id == objProduct._id)
			if (index != -1) {
				if (value > 0) {
					lstProductChoose[index].amount = value
				} else if (value == 0) {
					const newlstProductChoose = lstProductChoose.filter((item: any) => item.service_id != lstProductChoose[index].service_id)
					toast({
						title: 'Cập nhật thành công.',
						position: 'top',
						description: `${objProduct.name}`,
						status: 'success',
						duration: 2000,
						isClosable: true,
					})
					return setListProductChoose(newlstProductChoose)
				}
				lstProductChoose[index].total = (lstProductChoose[index].amount * lstProductChoose[index].cost) - lstProductChoose[index].discount * 1
				toast({
					title: 'Cập nhật thành công.',
					position: 'top',
					description: `${objProduct.name}`,
					status: 'success',
					duration: 2000,
					isClosable: true,
				})
				return setListProductChoose(lstProductChoose)
			} else if (index == -1) {
				if (value > 0) {
					lstProductChoose.push(newProduct)
					setListProductChoose(lstProductChoose)
					toast({
						title: 'Cập nhật thành công.',
						position: 'top',
						description: `${objProduct.name}`,
						status: 'success',
						duration: 2000,
						isClosable: true,
					})
				}
			}
		} else {
			if (value > 0) {
				lstProductChoose.push(newProduct)
				setListProductChoose(lstProductChoose)
				toast({
					title: 'Cập nhật thành công.',
					position: 'top',
					description: ` ${newProduct.service_name}`,
					status: 'success',
					duration: 2000,
					isClosable: true,
				})
			}
		}
	}

	useEffect(() => {
		const total = listProductChoose.reduce((n, { total }) => n + total * 1, 0)
		setTotal(total)
	}, [listProductChoose])

	const handleDiscountPrice = (e: any, obj: any) => {
		const percent = e.target.value * 1

		const lstProductChoose = [...listProductChoose]

		const index = lstProductChoose.findIndex((item: any) => item.service_id == obj.service_id)
		if (index != -1) {
			if (percent == 0) {
				lstProductChoose[index].discount = 0
				lstProductChoose[index].total = (lstProductChoose[index].amount * lstProductChoose[index].cost)
				return setListProductChoose(lstProductChoose)
			}
			const discount = ((lstProductChoose[index].cost * 1) * (lstProductChoose[index].amount * 1)) * ((percent) / 100)
			lstProductChoose[index].discountPercent = percent
			lstProductChoose[index].discount = discount
			lstProductChoose[index].total = (lstProductChoose[index].amount * lstProductChoose[index].cost) - discount

			setListProductChoose(lstProductChoose)
		}
	}

	const handleDiscountPriceVND = (e: any, obj: any) => {
		const percent = e.target.value * 1

		const lstProductChoose = [...listProductChoose]

		const index = lstProductChoose.findIndex((item: any) => item.service_id == obj.service_id)
		if (index != -1) {
			if (percent == 0) {
				lstProductChoose[index].discount = 0
				lstProductChoose[index].total = (lstProductChoose[index].amount * lstProductChoose[index].cost)
				return setListProductChoose(lstProductChoose)
			}
			const discount = percent
			// lstProductChoose[index].discount = discount
			if (((lstProductChoose[index].amount * lstProductChoose[index].cost) - discount) >= 0) {
				lstProductChoose[index].discount = discount
				if (lstProductChoose[index].discountPercent) {
					lstProductChoose[index].discountPercent = null
				}
				lstProductChoose[index].total = (lstProductChoose[index].amount * lstProductChoose[index].cost) - discount
			}

			setListProductChoose(lstProductChoose)
		}
	}

	const createBill = async () => {
		if (customer && customer._id && bill && bill.description && bill.isPaid != -1 && total && listProductChoose && listProductChoose.length > 0) {
			const listProductChooseObj = [...listProductChoose]
			const lsProductChooseObj = listProductChooseObj.map((product) => {
				product.amount = product.amount + ""
				product.discount = product.discount + ""
				product.total = product.total + ""

				return product
			})
			const abc: Employee[] = lsProductChooseObj
			const id: string = customer._id

			const objData = {
				customer_userid: id,
				description: bill.description,
				service_list: abc,
				total: total + "",
				isPaid: bill.isPaid == 0 ? true : false,
				noteWhenNotPaid: bill.noteWhenNotPaid,
				created_userid: ''
			}
			if (bill.isPaid == 2 && bill.noteWhenNotPaid == "") {
				return alert("Đối với hoá đơn trả góp, vui lòng ghi chú vào phần 'Ghi chú hoá đơn' số tiền còn thiếu!")
			}

			const tokenLocal = localStorage.getItem("LoginInfo")
			if (tokenLocal) {
				let id = ''
				const tokenParse = JSON.parse(tokenLocal)
				id = tokenParse.data[0]._id
				objData.created_userid = id
				const result: any = await dispatch(getPostsCreateBill(objData))
				if (result && result.status === "SUCCESS") {
					// reload()
					// getDataProductService()
					// setListProductChoose([])
					// setCustomer(null)
					// setBill({
					// 	description: '',
					// 	isPaid: -1,
					// 	noteWhenNotPaid: '',
					// })
					// setTotal(null)
					onOpen2()
				}
			}
		}
	}

	const handleGeneratePdf = () => {
		reportTemplateRef.current.save()
	};


	const min = 'Ít nhất 3 kí tự.'
	const max = 'Giới hạn 50 kí tự.'
	const required = 'Trường thông tin bắt buộc.'

	const updateIsPaidBill = Yup.object().shape({
		noteWhenNotPaid: Yup.string()
			.min(2, min)
			.max(200, max)
			.required(required),
	});


	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>

			<Tabs>
				<TabList>
					<Tab>Thanh toán</Tab>
					<Tab onClick={reload}>Danh sách toàn bộ hoá đơn</Tab>
					<Tab onClick={reload}>Danh sách hoá đơn chưa thanh toán</Tab>
				</TabList>

				<TabPanels minH={'100vh'}>
					<TabPanel>
						<SimpleGrid
							mb='20px'
							columns={{ sm: 1, md: 2 }}
							spacing={{ base: "20px", xl: "20px" }}>
							{/* <Flex> */}

							<Box borderColor='gray.200' style={{}}>
								<Text color={textColor} fontSize='lg' fontWeight='700'>Khách hàng</Text>
								<Input value={customerTxt} placeholder='Tìm khách hàng...' onChange={(e: any) => filterCustomer(e)} />
								{listCustomerFilter && listCustomerFilter.length > 0 ?
									<Box style={{ maxHeight: '300px', overflow: 'auto', padding: "10px" }} boxShadow='md' rounded='md'>
										{listCustomerFilter.map((item: any, index: any) => {
											return <Flex key={index} m="0 2px 2px 2px" p="1" style={index == listCustomerFilter.length - 1 ? {} : { borderBottom: "1px solid #d8d8d8" }} justify='space-between'>
												<Center>
													<Box>{item.name} - {item.phone_number}</Box>
												</Center>
												<Button colorScheme="pink" variant='ghost' onClick={() => {
													setCustomerTxt(item.name)
													setCustomer(item)
													setListCustomerFilter([])
												}
												}
												>Chọn</Button>
											</Flex>
										})}
									</Box>
									: ""}
							</Box>
							<br />
							<Box>
								{/* <Flex justify='space-between'> */}
								<Box borderColor='gray.200' style={{}}>
									<Text color={textColor} fontSize='lg' fontWeight='700'>Sản phẩm</Text>
									<Input placeholder='Tìm sản phẩm...' onChange={(e: any) => filterProduct(e)} />
									<Box style={{ maxHeight: '300px', overflow: 'auto', padding: "10px" }} boxShadow='md' rounded='md'>
										{
											listProductFilter && listProductFilter.length > 0 ? listProductFilter.map((item: any, index: any) => {

												let defaultvalue = 0
												if (listProductChoose && listProductChoose.length > 0) {
													const ProductChoose = listProductChoose.findIndex((product: any, index: any) => product.service_id == item._id)
													if (ProductChoose != -1) {
														defaultvalue = listProductChoose[ProductChoose].amount
													}
												}

												return <Flex m="0 2px 2px 2px" p="1" style={index == listProductFilter.length - 1 ? {} : { borderBottom: "1px solid #d8d8d8" }} key={index} justify='space-between'>
													<Center>
														<Box>
															<Flex>
																<Text>{item.name}</Text>
																<Code> - {formatMoney(item.cost, 0)} vnd</Code>
															</Flex>
															{/* {item.name} - {formatMoney(item.cost, 0)} vnd */}
														</Box>
													</Center>
													{/* <Button colorScheme="green" variant='ghost'>Thêm</Button> */}
													<Text color="white">{defaultvalue}</Text>

													<Box>
														<Flex>
															<Center p="0 5px 0 0">
																<Text >Còn {item.quantity} sản phẩm </Text>
															</Center>
															<NumberInput size='md' maxW={20} defaultValue={defaultvalue} min={0} max={item.quantity}
																onChange={(value) => {
																	handleIncrementProductABC(value, item)
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														</Flex>
													</Box>
												</Flex>
											})
												: listProduct && listProduct.map((item: any, index: any) => {

													let defaultvalueProduct = 0
													if (listProductChoose && listProductChoose.length > 0) {
														const indexProductChoose = listProductChoose.findIndex((product: any, index: any) => product.service_id == item._id)
														if (indexProductChoose != -1) {
															defaultvalueProduct = listProductChoose[indexProductChoose].amount
														}
													}

													return <Tooltip
														label={item.quantity == 0 ? "Hết hàng!" : `${item.quantity} sản phẩm có sẵn`}
														placement="left"
														bg={item.quantity == 0 ? "red" : 'blue'}
													>
														<Flex
															m="0 2px 2px 2px" p="1"
															style={index == listProduct.length - 1 ? {} : { borderBottom: "1px solid #d8d8d8" }} key={index} justify='space-between'
															bg={item.quantity == 0 ? "red.100" : ""}
															cursor={item.quantity == 0 ? "not-allowed" : "default"}
														>
															<Center>
																<Box>
																	<Flex>
																		<Text color={item.quantity == 0 ? "grey" : ""}>{item.name}</Text>
																		<Code color={item.quantity == 0 ? "grey" : ""} bg={item.quantity == 0 ? "red.100" : ""}> - {formatMoney(item.cost, 0)} vnd</Code>
																	</Flex>
																	{/* {item.name} - {formatMoney(item.cost, 0)} vnd */}
																</Box>
															</Center>
															{/* <Button colorScheme="green" variant='ghost'>Thêm</Button> */}

															<Text color="white">{defaultvalueProduct}</Text>

															<Box>
																<Flex>
																	<Center p="0 5px 0 0">
																		<Text color="grey"> {item.quantity} sản phẩm có sẵn </Text>
																	</Center>
																	<NumberInput size='md' maxW={20} defaultValue={defaultvalueProduct} min={0} max={item.quantity}
																		onChange={(value) => {
																			handleIncrementProductABC(value, item)
																		}}>
																		<NumberInputField />
																		<NumberInputStepper>
																			<NumberIncrementStepper />
																			<NumberDecrementStepper />
																		</NumberInputStepper>
																	</NumberInput>
																</Flex>
															</Box>
														</Flex>
													</Tooltip>
												})
										}
									</Box>
								</Box>
								<br />
								<Box borderColor='gray.200' style={{}}>
									<Text color={textColor} fontSize='lg' fontWeight='700'>Dịch vụ</Text>
									<Input placeholder='Tim dịch vụ...' onChange={(e: any) => filterService(e)} />
									<Box style={{ maxHeight: '300px', overflow: 'auto', padding: "10px" }} boxShadow='md' rounded='md'>
										{listServiceFilter && listServiceFilter.length ? listServiceFilter.map((item: any, index: any) => {

											let defaultvalueService = 0
											if (listProductChoose && listProductChoose.length > 0) {
												const indexProductChoose = listProductChoose.findIndex((product: any, index: any) => product.service_id == item._id)
												if (indexProductChoose != -1) {
													defaultvalueService = listProductChoose[indexProductChoose].amount
												}
											}

											return <Flex key={index} m="0 2px 2px 2px" p="1" style={index == listServiceFilter.length - 1 ? {} : { borderBottom: "1px solid #d8d8d8" }} justify='space-between'>
												<Center>
													<Box>
														<Flex>
															<Text>{item.name}</Text>
															<Code> - {formatMoney(item.cost, 0)} vnd</Code>
														</Flex>
														{/* {item.name} - {formatMoney(item.cost, 0)} vnd */}
													</Box>
												</Center>
												{/* <Button colorScheme="purple" variant='ghost'>Thêm</Button> */}
												<Text color="white">{defaultvalueService}</Text>
												<NumberInput size='md' maxW={20} defaultValue={defaultvalueService} min={0} max={100}
													onChange={(value) => {
														handleIncrementProductABC(value, item)
													}}>
													<NumberInputField />
													<NumberInputStepper>
														<NumberIncrementStepper />
														<NumberDecrementStepper />
													</NumberInputStepper>
												</NumberInput>
											</Flex>
										})
											: listService && listService.map((item: any, index: any) => {

												let defaultvalueServices = 0
												if (listProductChoose && listProductChoose.length > 0) {
													const indexProductChoose = listProductChoose.findIndex((product: any, index: any) => product.service_id == item._id)
													if (indexProductChoose != -1) {
														defaultvalueServices = listProductChoose[indexProductChoose].amount
													}
												}

												return <Flex key={index} m="0 2px 2px 2px" p="1"
													style={index == listService.length - 1 ? {} : { borderBottom: "1px solid #d8d8d8" }} justify='space-between'>
													<Center>
														<Box>
															<Flex>
																<Text>{item.name}</Text>
																<Code> - {formatMoney(item.cost, 0)} vnd</Code>
															</Flex>
															{/* {item.name} - {formatMoney(item.cost, 0)} vnd */}
														</Box>
													</Center>
													{/* <Button colorScheme="purple" variant='ghost'>Thêm</Button> */}
													<Text color="white">{defaultvalueServices}</Text>
													<NumberInput size='md' maxW={20} defaultValue={defaultvalueServices} min={0} max={100}
														onChange={(value) => {
															handleIncrementProductABC(value, item)
														}}>
														<NumberInputField />
														<NumberInputStepper>
															<NumberIncrementStepper />
															<NumberDecrementStepper />
														</NumberInputStepper>
													</NumberInput>
												</Flex>
											})
										}
									</Box>
								</Box>
								{/* </Flex> */}
								<br />
								<Box borderColor='gray.200' rounded='md' style={{}}>
									<Text color={textColor} fontSize='lg' fontWeight='700'>Miêu tả</Text>
									<Input value={bill.description} name="description" placeholder='Miêu tả' onChange={handleOnChange} />
									<br />
									<FormControl>
										<Text color={textColor} fontSize='lg' fontWeight='700'>Tình trạng</Text>
										<Select name='isPaid' placeholder='Tình trạng' onChange={handleOnChange}>
											<option value={0}>Đã thanh toán</option>
											<option value={1}>Chưa thanh toán</option>
											<option value={2}>Trả góp</option>
										</Select>
									</FormControl><br />
									<Text color={textColor} fontSize='lg' fontWeight='700'>Ghi chú hoá đơn</Text>
									<Input name='noteWhenNotPaid' placeholder='Ghi chú hoá đơn' onChange={handleOnChange} /><br />
									<div style={{ textAlign: 'right' }}>
										<div>
											<Button
												mt={4}
												mr={3}
												colorScheme='blue'
												type='submit'
												w="100%"
												variant='outline'
												onClick={createBill}
												disabled={customer && customer._id && bill && bill.description && bill.isPaid != -1 && total && listProductChoose && listProductChoose.length > 0 ? false : true}
											>
												Xuất hoá đơn
											</Button>
										</div>
									</div>
									{/* </Form> */}
								</Box>
							</Box>
							<Box>
								<Box rounded="md" boxShadow='md' h="100%" style={{
									position: "relative",
									overflow: "hidden",
									boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between'
								}}
								>
									<div>
										<SimpleGrid
											mb='20px'
											columns={{ sm: 1, md: 2 }}
											spacing={{ base: "20px", xl: "20px" }}
										>
											<div style={{ padding: "20px 20px 0 20px" }}>
												<img src={logo5} style={{ width: '70%' }} />
											</div>
											<div style={{ textAlign: "right", padding: "80px 40px 0 0" }}>
												<Text color="#A0AEC0" fontSize='3xl' fontWeight='700'>HOÁ ĐƠN</Text>
												<Text color='grey.300' >
													<Icon as={MdCalendarToday} />{formatDate(new Date)}
												</Text>
											</div>
										</SimpleGrid>
										<Box p="0px 20px">
											<SimpleGrid
												mb='20px'
												columns={{ sm: 1, md: 2 }}
												spacing={{ base: "20px", xl: "20px" }}
												bg="gray.200"
												p="20px 20px"
											>
												<Box>
													<Text color="GrayText" fontSize='xl' fontWeight='700'>Khách hàng</Text>
													<Text color="GrayText" fontSize='md'>{customer ? customer.name : "Họ và tên"} - {customer ? customer.phone_number : "Số điện thoại"}</Text>
													<Text color="GrayText" fontSize='md'>{customer ? customer.address : "Nguyễn Văn A"}</Text>
												</Box>
												<Box>
													<Text color="GrayText" fontSize='xl' fontWeight='700'>Miêu tả</Text>
													<Text color="GrayText" fontSize='md'>{bill.description ? bill.description : "Miêu tả"}</Text>
													<div style={{ display: 'flex', flexWrap: "wrap" }}>
														{bill.isPaid == 0 ?
															<Badge variant='solid' colorScheme='green'>Đã thanh toán</Badge>
															:
															(bill.isPaid == 1 ? <Badge variant='solid' colorScheme='red'>Chưa thanh toán</Badge> : <Badge variant='solid' colorScheme='orange'>Trả góp</Badge>)
														}&ensp;
														<Text color="GrayText" fontSize='md'>{bill.noteWhenNotPaid ? bill.noteWhenNotPaid : ""}</Text>
													</div>
												</Box>
											</SimpleGrid>
											<Box overflowX={{ sm: 'scroll', lg: 'hidden' }}>
												<Table colorScheme='blackAlpha'>
													<Thead>
														<Tr>
															<Th style={{ padding: "5px", width: '30%' }}>Sản phẩm / dịch vụ</Th>
															<Th style={{ padding: "5px" }}>Số lượng</Th>
															<Th style={{ padding: "5px" }}>Đơn giá</Th>
															<Th style={{ padding: "5px" }}>Giảm</Th>
															<Th style={{ padding: "5px" }} isNumeric>Thành tiền</Th>
														</Tr>
													</Thead>
													<Tbody>
														{listProductChoose && listProductChoose.length > 0 ? //hiep
															listProductChoose.map((item, index) => <Tr key={index}>
																<Td style={{ padding: "5px" }}>
																	<Text style={{ marginRight: '5px' }}>{item.service_name}</Text>
																</Td>
																<Td style={{ padding: "5px" }}>{item.amount}</Td>
																<Td style={{ padding: "5px" }}>
																	<Popover>
																		<PopoverTrigger>
																			<Button color="grey" size='sm'><Code>{formatMoney(item.cost, 0)}</Code></Button>
																		</PopoverTrigger>
																		<PopoverContent>
																			<PopoverArrow />
																			<PopoverCloseButton />
																			<PopoverHeader>
																				<Tooltip label={`${ formatMoney(item?.real_cost || 0, 0)} VND`}>
																					<Flex>
																						<Text fontWeight="700">Giảm giá -&nbsp;</Text>
																						<Text as="mark" fontWeight="700">{item.service_name}</Text>
																					</Flex>
																				</Tooltip>
																			</PopoverHeader>
																			<PopoverBody borderColor='blue.700'>
																				<Tabs colorScheme='green' style={{ padding: '0' }}>
																					<TabList style={{ padding: '0' }}>
																						<Tab style={{ padding: '10px 0', marginRight: '10px' }}>Theo phần trăm(%)</Tab>
																						<Tab style={{ padding: '10px 0' }}>Theo giá(VND)</Tab>
																					</TabList>
																					<TabPanels style={{ padding: '0' }}>
																						<TabPanel style={{ padding: '15px 0 0 0' }}>
																							<Select onChange={(value) => handleDiscountPrice(value, item)}>
																								{discountOption.map((option: any, index: any) => {
																									return <option key={index} value={option}>{option}%</option>
																								})}
																							</Select>
																						</TabPanel>
																						<TabPanel style={{ padding: '15px 0 0 0' }}>
																							<Input type="number" placeholder='Giảm theo giá...' onChange={(e: any) => handleDiscountPriceVND(e, item)} />
																						</TabPanel>
																					</TabPanels>
																				</Tabs>
																			</PopoverBody>
																		</PopoverContent>
																	</Popover>
																</Td>
																<Td style={{ padding: "5px" }}>
																	<Tooltip size="lg" color="black" label={`Đã giảm ${formatMoney(item.discount, 0)}đ trên tổng số lượng sản phẩm.`} bg='yellow'>
																		{/* <Text size='sm' as="mark"> */}
																		<Code>
																			{item.discount != 0 ?
																				item.discountPercent ? item.discountPercent + "%" :
																					("-" + formatMoney(item.discount, 0))
																				: null}
																		</Code>
																		{/* </Text> */}
																	</Tooltip>
																</Td>
																<Td style={{ padding: "5px" }} isNumeric><Code>{formatMoney(item.total, 0)}đ</Code></Td>
															</Tr>)
															: null}
													</Tbody>
													<Tfoot>
														<Tr>
															<Th style={{ padding: "5px" }}>Tổng tiền</Th>
															<Th style={{ padding: "5px" }}></Th>
															<Th style={{ padding: "5px" }}></Th>
															<Th style={{ padding: "5px" }}></Th>
															<Th style={{ padding: "5px" }} isNumeric>
																<Code color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>
																	{formatMoney(total, 0)} vnd
																</Code>
															</Th>
														</Tr>
													</Tfoot>
												</Table>
											</Box>
										</Box>
									</div>

									<Center>
										<Text p="20px" color="gray.400" as="cite">Trao trọn giá trị cho vẻ đẹp làn da của bạn</Text>
									</Center>
								</Box>
							</Box>

							{/* </Flex> */}
						</SimpleGrid>
						<Modal closeOnOverlayClick={false} isOpen={isOpen2} onClose={() => {
							// onClose2()
							// setListProductChoose([])
							// setCustomer(null)
							// setBill({
							// 	description: '',
							// 	isPaid: -1,
							// 	noteWhenNotPaid: '',
							// })
							// setTotal(null)
							window.location.reload()
						}} size="4xl">
							<ModalOverlay />
							<ModalContent>
								<ModalHeader>
									Xuất hoá đơn thành công!
								</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<Tabs>
										<TabList>
											<Tab>Xuất trên máy tình(PDF file)</Tab>
											<Tab>Xuất trên điện thoại.</Tab>
										</TabList>

										<TabPanels>
											<TabPanel>{/* Hiển thị xoá đơn xuất */}
												<PDFExport ref={reportTemplateRef} paperSize="A4"
													fileName={`${customer && customer.name ? customer.name : "Tên"}-${customer && customer.phone_number ? customer.phone_number : "SDT"}-${formatDate(new Date)}`}
												>
													<Box display={{ sm: 'none', xl: 'block' }}>
														<Box rounded="md" boxShadow='md' h="100%" style={{
															position: "relative",
															overflow: "hidden",
															boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'space-between'
														}}
														>
															<div>
																<SimpleGrid
																	mb='20px'
																	columns={{ sm: 1, md: 2 }}
																	spacing={{ base: "20px", xl: "20px" }}
																>
																	<div style={{ padding: "20px 20px 0 20px" }}>
																		<img src={logobill} style={{ width: '70%' }} />
																	</div>
																	<div style={{ textAlign: "right", padding: "80px 40px 0 0" }}>
																		<Text fontSize='3xl' fontWeight='700'>HOÁ ĐƠN</Text>
																		<Text color='grey.300' >
																			<Icon as={MdCalendarToday} />{formatDate(new Date)}
																		</Text>
																	</div>
																</SimpleGrid>
																<Box p="0px 20px">
																	<SimpleGrid
																		mb='20px'
																		columns={{ sm: 1, md: 2 }}
																		spacing={{ base: "20px", xl: "20px" }}
																		bg="gray.200"
																		p="20px 20px"
																	>
																		<Box>
																			<Text fontSize='xl' fontWeight='700'>Khách hàng</Text>
																			<Text fontSize='md'>{customer ? customer.name : "Họ và tên"} - {customer ? customer.phone_number : "Số điện thoại"}</Text>
																			<Text fontSize='md'>{customer ? customer.address : "Nguyễn Văn A"}</Text>
																		</Box>
																		<Box>
																			<Text fontSize='xl' fontWeight='700'>Miêu tả</Text>
																			<Text fontSize='md'>{bill.description ? bill.description : "Miêu tả"}</Text>
																			<div style={{ display: 'flex', flexWrap: "wrap" }}>
																				{bill.isPaid == 0 ?
																					<Badge variant='solid' colorScheme='green'>Đã thanh toán</Badge>
																					:
																					(bill.isPaid == 1 ? <Badge variant='solid' colorScheme='red'>Chưa thanh toán</Badge> : <Badge variant='solid' colorScheme='orange'>Trả góp</Badge>)
																				}&ensp;
																				<Text fontSize='md'>{bill.noteWhenNotPaid ? bill.noteWhenNotPaid : ""}</Text>
																			</div>
																		</Box>
																	</SimpleGrid>
																	<Box overflowX={{ sm: 'scroll', lg: 'hidden' }}>
																		<Table colorScheme='blackAlpha'>
																			<Thead>
																				<Tr>
																					<Th style={{ padding: "5px", width: '30%' }} color="black" fontWeight='700'>Sản phẩm / dịch vụ</Th>
																					<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Số lượng</Th>
																					<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Đơn giá</Th>
																					<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Giảm</Th>
																					<Th style={{ padding: "5px" }} color="black" fontWeight='700' isNumeric>Thành tiền</Th>
																				</Tr>
																			</Thead>
																			<Tbody>
																				{listProductChoose && listProductChoose.length > 0 ?
																					listProductChoose.map((item, index) => <Tr key={index}>
																						<Td style={{ padding: "5px" }}>
																							<Text style={{ marginRight: '5px' }}>{item.service_name}</Text>
																						</Td>
																						<Td style={{ padding: "5px" }}>{item.amount}</Td>
																						<Td style={{ padding: "5px" }}>{formatMoney(item.cost, 0)}đ</Td>
																						<Td style={{ padding: "5px" }}>
																							<Text>
																								{/* {item.discount != 0 ? "-" + formatMoney(item.discount, 0) + "đ" : null} */}
																								{item.discount != 0 ?
																									item.discountPercent ? item.discountPercent + "%" :
																										("-" + formatMoney(item.discount, 0) + "đ")
																									: null}
																							</Text>
																						</Td>
																						<Td style={{ padding: "5px" }} isNumeric>{formatMoney(item.total, 0)}đ</Td>
																					</Tr>)
																					: null}
																			</Tbody>
																			<Tfoot>
																				<Tr>
																					<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Tổng tiền</Th>
																					<Th style={{ padding: "5px" }}></Th>
																					<Th style={{ padding: "5px" }}></Th>
																					<Th style={{ padding: "5px" }}></Th>
																					<Th style={{ padding: "5px" }} isNumeric>
																						<Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>
																							{formatMoney(total, 0)} vnd
																						</Text>
																					</Th>
																				</Tr>
																			</Tfoot>
																		</Table>
																	</Box>
																</Box>
															</div>

															<Center>
																<Text p="20px" as="cite">Trao trọn giá trị cho vẻ đẹp làn da của bạn</Text>
															</Center>
														</Box>
													</Box>
												</PDFExport>
												<br />
												<Box display={{ sm: 'none', xl: 'block' }}>
													<div style={{ textAlign: 'right', marginRight: '30px' }}>
														<Button colorScheme='blue' onClick={handleGeneratePdf}><Icon as={MdPrint} />&ensp; In hoá đơn</Button>
													</div>
												</Box>
											</TabPanel>
											<TabPanel>
												<Box>
													<Box h="100%"
														style={{
															position: "relative",
															overflow: "hidden",
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'space-between',
														}}
														ref={ref}
														bg="white"
													>
														<Box display={{ sm: 'block', xl: 'none' }}>
															<div>
																<SimpleGrid
																	mt='20px'
																	columns={{ sm: 1, md: 2 }}
																	spacing={{ base: "20px", xl: "20px" }}
																>
																	<div>
																		<img src={logobill} style={{ width: '100%' }} />
																	</div>
																	<div style={{ textAlign: "center" }}>
																		<Text fontSize='3xl' fontWeight='700'>HOÁ ĐƠN</Text>
																		<Text color='grey.300' >
																			{/* {formatDate(new Date)} */}
																		</Text>
																	</div>
																</SimpleGrid>
																<Box p="0px 0px">
																	<SimpleGrid
																		mb='20px'
																		columns={{ sm: 1, md: 2 }}
																		spacing={{ base: "20px", xl: "20px" }}
																		p="0px 0px"
																	>
																		<Box p="0px 0px">
																			<Text fontSize='md'>Khách hàng</Text>
																			<Text fontSize='md'>{customer ? customer.name : "Họ và tên"} - {customer ? customer.phone_number : "Số điện thoại"}</Text>
																			<Text fontSize='md'>{customer ? customer.address : "Nguyễn Văn A"}</Text>
																		</Box>
																		<Box>
																			<Text fontSize='md'>Miêu tả</Text>
																			<Text fontSize='md'>{bill.description ? bill.description : "Miêu tả"}</Text>
																			<div style={{ display: 'flex', flexWrap: "wrap" }}>
																				{bill.isPaid == 0 ?
																					<Text as='b'>Đã thanh toán</Text>
																					:
																					(bill.isPaid == 1 ? <Text as='b'>Chưa thanh toán</Text> : <Text as='b'>Trả góp</Text>)
																				}&ensp;
																				<Text fontSize='md'>{bill.noteWhenNotPaid ? bill.noteWhenNotPaid : ""}</Text>
																			</div>
																		</Box>
																	</SimpleGrid>
																	<br />
																	<Box overflowX={{ sm: 'scroll', lg: 'hidden' }}>
																		<Flex justify='space-between'>
																			<Text as='b'>Số lượng</Text>
																			<Text as='b'>Giảm</Text>
																			<Text as='b'>Thành tiền</Text>
																		</Flex>
																		<hr />
																		<div>
																			{listProductChoose && listProductChoose.length > 0 ?
																				listProductChoose.map((item, index) =>
																					<div key={index}>
																						<Text>
																							<div style={{ width: "100%", display: "block" }}>{item.service_name}</div>
																						</Text>
																						<Flex justify='space-between' key={index}>
																							<div style={{ padding: "5px" }}>{item.amount}</div>
																							<div style={{ padding: "0px" }}>
																								<Text>
																									{item.discount != 0 ?
																										item.discountPercent ? item.discountPercent + "%" :
																											("-" + formatMoney(item.discount, 0) + "đ")
																										: null}
																								</Text>
																							</div>
																							<div style={{ padding: "0px" }} >{formatMoney(item.total, 0)}đ</div>
																						</Flex>
																						<hr />
																					</div>)
																				: null}
																		</div>

																		<Flex justify='space-between' mb='37px'>
																			<Text >Tổng tiền</Text>
																			<Text as='b'><Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>
																				{formatMoney(total, 0)} vnd
																			</Text></Text>
																		</Flex>
																	</Box>
																</Box>
															</div>

															<Center>
																<Text mb='37px' as="cite">Trao trọn giá trị cho vẻ đẹp làn da của bạn</Text>
															</Center>
														</Box>
													</Box>
													<br />
													<Box>
														<div style={{ textAlign: 'right' }}>
															<Button colorScheme='blue' onClick={() => onButtonClick(`${customer && customer.name ? customer.name : "Tên"}-${customer && customer.phone_number ? customer.phone_number : "SDT"}-${formatDate(new Date)}`)}><Icon as={MdPrint} />&ensp; In hoá đơn</Button>
														</div>
													</Box>
												</Box>
											</TabPanel>
										</TabPanels>
									</Tabs>


									<br />
								</ModalBody>
							</ModalContent>
						</Modal>
					</TabPanel>
					<TabPanel style={{ padding: '0'}}>
						<br />
						<Table {...getTableProps()} variant='striped' color='gray.500' mb='24px'>
							<Thead>
								{headerGroups.map((headerGroup, index) => (
									<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
										{headerGroup.headers.map((column, index) => (
											<Th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												px='5px'
												key={index}
												borderColor={borderColor}
											>
												<Flex
													justify='space-between'
													align='center'
													fontSize={{ sm: '10px', lg: '12px' }}
													color='gray.400'>
													{column.render('Header')}
												</Flex>
											</Th>
										))}
									</Tr>
								))}
							</Thead>
							<Tbody {...getTableBodyProps()}>
								{page.map((row, index) => {
									prepareRow(row);
									return (
										<Tr {...row.getRowProps()} key={index}>
											{row.cells.map((cell: any, index) => {
												
												if (cell.column.Header == 'Ngày' || cell.column.Header == 'Ngày tạo' || cell.column.Header == 'Ngày chỉnh sửa') {
													cell.value = formatDate(cell.value)

													return <Td
														{...cell.getCellProps()}
														key={index}
														fontSize={{ sm: '14px' }}
														minW={{ sm: '150px', md: '200px', lg: 'auto' }}
														borderColor='transparent'
														p="5px"
													>
														<Text color={textColor} fontSize='sm'>
															<Icon as={MdOutlineEditCalendar} color='blue' /> {cell.value}
														</Text>
													</Td>
												}
												if (cell.column.Header == 'Khách hàng') {
													return (
														<Td
															{...cell.getCellProps()}
															key={index}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'
															style={{ cursor: 'pointer' }}
															onClick={() => showDetailBill(cell)}
															p="5px"
														>
															<Tooltip label='Chi tiết' bg='blue'>
																<Flex align='center'>
																	<Icon as={MdOutlinePersonPin} w='24px' h='24px' me='5px' color='pink.500' />
																	<Text color={textColor} fontSize='sm' fontWeight='700'>
																		{cell.value}
																	</Text>
																</Flex>
															</Tooltip>
														</Td>
													);
												}

												if (cell.column.Header == 'Nhân viên tạo') {
													return (
														<Td
															{...cell.getCellProps()}
															key={index}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'
															p="5px"
														>
															<Text color={textColor} fontSize='sm'>
																<Icon as={MdOutlinePersonOutline} color='green.500' />{cell.value}
															</Text>
														</Td>
													);
												}

												if (cell.column.Header == 'Ghi chú') {
													return (
														<Td
															{...cell.getCellProps()}
															key={index}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'
															p="5px"
														>
															<Text color={textColor} fontSize='sm'>
																<Icon as={MdNotes} color='pink.500' />{cell.value}
															</Text>
														</Td>
													);
												}

												if (cell.column.Header == 'Mô tả') {
													return (
														<Td
															{...cell.getCellProps()}
															key={index}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'
															p="5px"
														>
															<Text color={textColor} fontSize='sm'>
																<Icon as={MdNotes} color='pink.500' />{cell.value}
															</Text>
														</Td>
													);
												}

												if (cell.column.Header == 'Số điện thoại') {
													return (
														<Td
															{...cell.getCellProps()}
															key={index}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'
															// style={{ cursor: 'pointer' }}
															// onClick={() => showDetailBill(cell)}
															p="5px"
														>
															{/* <Tooltip label='Chi tiết' bg='green'> */}
															<Text color={textColor} fontSize='sm'>
																<Icon as={MdPhoneIphone} color='grey.500' />{cell.value}
															</Text>
															{/* </Tooltip> */}
														</Td>
													);
												}

												if (cell.column.Header == 'Tổng tiền') {
													return (
														<Td
															{...cell.getCellProps()}
															key={index}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'
															p="5px"
														>
															<Text color={textColor} fontSize='sm'>
																<Icon as={MdAttachMoney} color='green.500' />{formatMoney(cell.value, 0)} vnd
															</Text>
														</Td>
													);
												}

												if (cell.column.Header === 'Tình trạng') {

													return <Td
														{...cell.getCellProps()}
														key={index}
														fontSize={{ sm: '14px' }}
														minW={{ sm: '150px', md: '200px', lg: 'auto' }}
														borderColor='transparent'
														p="5px"
													>
														<Flex align='center'>
															<Popover
																closeOnBlur={false}
															>
																{({ isOpen, onClose }) => (
																	<>
																		<PopoverTrigger>
																			<Button disabled={cell.value === 'Đã thanh toán' ? true : false}>
																				<Icon
																					w='24px'
																					h='24px'
																					me='5px'
																					color={
																						cell.value === 'Đã thanh toán' ? (
																							'green.500'
																						) : cell.value === 'Chưa thanh toán' ? (
																							'red.500'
																						) : cell.value === undefined ? (
																							'orange.500'
																						) : null
																					}
																					as={
																						cell.value === 'Đã thanh toán' ? (
																							MdCheckCircle
																						) : cell.value === 'Chưa thanh toán' ? (
																							MdCancel
																						) : cell.value === undefined ? (
																							MdOutlineError
																						) : null
																					}
																				/>
																				<Text color={textColor} fontSize='sm'>
																					{cell.value}
																				</Text>
																			</Button>
																		</PopoverTrigger>
																		<PopoverContent>
																			<PopoverArrow />
																			<PopoverCloseButton />
																			<PopoverHeader color={'blackAlpha.800'} fontSize={20} fontWeight={700}>Xác nhận đã thanh toán?</PopoverHeader>
																			<PopoverBody>

																				<Formik
																					initialValues={{
																						noteWhenNotPaid: '',
																					}}
																					validationSchema={updateIsPaidBill}
																					onSubmit={async values => {
																						const newValue: any = { ...values }
																						newValue.isPaid = true
																						const tokenLocal = localStorage.getItem("LoginInfo")
																						if (tokenLocal) {
																							let id = ''
																							const tokenParse = JSON.parse(tokenLocal)
																							id = tokenParse.data[0]._id
																							newValue.updated_userid = id
																							newValue.bill_id = cell.row.original._id
																							const result: any = await dispatch(getPostsUpdateIsPaidBill(newValue))
																							onClose()
																							if (result && result.status === "SUCCESS") {
																								reload()
																							}
																						}
																					}}
																				>
																					{({ errors, touched }) => (
																						<Form>
																							<Code>
																								Khách hàng: {cell.row.original.customer_userid.name} <br />
																								- Địa chỉ: {cell.row.original.customer_userid.address}<br />
																								<br />
																								<hr />
																								<br />
																								- Ngày đến: {formatDate(cell.row.original.create_date)} <br />
																								- Miêu tả: {cell.row.original.description}<br />
																								- Ghi chú: {cell.row.original.noteWhenNotPaid}<br />
																								<br />
																								<hr />
																								<br />
																							</Code>
																							<Field name="noteWhenNotPaid">
																								{({ field, form }: any) => (
																									<FormControl isInvalid={errors.noteWhenNotPaid && touched.noteWhenNotPaid}>
																										<FormLabel>Ghi chú:</FormLabel>
																										<Textarea {...field} placeholder='Ghi chú thanh toán' />
																										<FormErrorMessage>{errors.noteWhenNotPaid}</FormErrorMessage>
																									</FormControl>
																								)}
																							</Field><br />
																							<div style={{ textAlign: 'right' }}>
																								<div>
																									<Button
																										colorScheme='blue'
																										isLoading={props.isSubmitting}
																										type='submit'
																									>
																										Cập nhật
																									</Button>
																								</div>
																							</div>
																						</Form>
																					)}
																				</Formik>
																			</PopoverBody>
																		</PopoverContent>
																	</>
																)}
															</Popover>
														</Flex>
													</Td>
												}

												if (cell.column.Header === 'Giới tính') {
													return <Td
														{...cell.getCellProps()}
														key={index}
														fontSize={{ sm: '14px' }}
														minW={{ sm: '150px', md: '200px', lg: 'auto' }}
														borderColor='transparent'
														p="5px"
													>
														<Flex align='center'>
															<Icon
																w='24px'
																h='24px'
																me='5px'
																color={
																	cell.value === 'Nam' ? (
																		'green.500'
																	) : cell.value === 'Nữ' ? (
																		'red.500'
																	) : cell.value === undefined ? (
																		'orange.500'
																	) : null
																}
																as={
																	cell.value === 'Nam' ? (
																		MdMale
																	) : cell.value === 'Nữ' ? (
																		MdFemale
																	) : cell.value === undefined ? (
																		MdOutlineError
																	) : null
																}
															/>
															<Text color={textColor} fontSize='sm'>
																{cell.value}
															</Text>
														</Flex>
													</Td>
												}

												return (
													<Td
														{...cell.getCellProps()}
														key={index}
														fontSize={{ sm: '14px' }}
														minW={{ sm: '150px', md: '200px', lg: 'auto' }}
														borderColor='transparent'
														p="5px"
													>
														<Text color={textColor} fontSize='sm'>
															{cell.value}
														</Text>
													</Td>
												);
											})}
										</Tr>
									);
								})}
							</Tbody>
						</Table>
						<Flex justify='space-between' align='center' px='37px'>
							<Flex>
								<Button colorScheme='teal' size='small' onClick={() => previousPage()} disabled={!canPreviousPage}>
									<Icon as={MdKeyboardArrowLeft} width='30px' height='30px' color='inherit' />
								</Button>&ensp;
								<span style={{ padding: '3px 5px 0 0' }}>
									<strong>
										{pageIndex + 1} / {pageOptions.length}
									</strong>{" "}
								</span>
								<Button colorScheme='teal' size='small' onClick={() => nextPage()} disabled={!canNextPage}>
									<Icon as={MdKeyboardArrowRight} width='30px' height='30px' color='inherit' />
								</Button>&ensp;
							</Flex>


							<select
								style={styleSelect}
								value={pageSize}
								onChange={(e) => {
									setPageSize(Number(e.target.value));
								}}
							>
								{[5, 10, 20, 30, 40, 50].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>
						</Flex>

						<Modal closeOnOverlayClick={false} isOpen={isOpen1} onClose={onClose1} size="3xl">
							<ModalOverlay />
							<ModalContent>
								<Box display={{ sm: 'block', xl: 'none' }}>
									<ModalHeader>
										Đã xuất!
									</ModalHeader>
									<ModalCloseButton />
									<ModalBody>
										<Box>
											<Box h="100%"
												style={{
													position: "relative",
													overflow: "hidden",
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'space-between',
												}}
												ref={ref}
												bg="white"
											>
												<Box>
													<div>
														<SimpleGrid
															mt='20px'
															columns={{ sm: 1, md: 2 }}
															spacing={{ base: "20px", xl: "20px" }}
														>
															<div>
																<img src={logobill} style={{ width: '100%' }} />
															</div>
															<div style={{ textAlign: "center" }}>
																<Text fontSize='3xl' fontWeight='700'>HOÁ ĐƠN</Text>
																<Text color='grey.300' >
																	{formatDate(detail && detail.create_date)}
																</Text>
															</div>
														</SimpleGrid>
														<Box p="0px 0px">
															<SimpleGrid
																mb='20px'
																columns={{ sm: 1, md: 2 }}
																spacing={{ base: "20px", xl: "20px" }}
																p="0px 0px"
															>
																<Box p="0px 0px">
																	<Text fontSize='md'>Khách hàng</Text>
																	<Text fontSize='md'>{detail ? detail.customer_username : "Họ và tên"} - {detail ? detail.customer_phone_number : "Số điện thoại"}</Text>
																	<Text fontSize='md'>{detail ? detail.address : "Nguyễn Văn A"}</Text>
																</Box>
																<Box>
																	<Text fontSize='md'>Miêu tả</Text>
																	<Text fontSize='md'>{detail ? detail.description : "Miêu tả"}</Text>
																	<div style={{ display: 'flex', flexWrap: "wrap" }}>
																		{detail && detail.isPaid == 0 ?
																			<Text as='b'>Đã thanh toán</Text>
																			:
																			(detail && detail.isPaid == 1 ? <Text as='b'>Chưa thanh toán</Text> : <Text as='b'>Trả góp</Text>)
																		}&ensp;
																		<Text fontSize='md'>{detail ? detail.noteWhenNotPaid : ""}</Text>
																	</div>
																</Box>
															</SimpleGrid>
															<br />
															<Box overflowX={{ sm: 'scroll', lg: 'hidden' }}>
																<Flex justify='space-between'>
																	<Text as='b'>Số lượng</Text>
																	<Text as='b'>Giảm</Text>
																	<Text as='b'>Thành tiền</Text>
																</Flex>
																<hr />
																<div>
																	{detail && detail.service_list && detail.service_list.length > 0 ?
																		detail.service_list.map((item: any, index: any) =>
																			<div key={index}>
																				<Text>
																					<div style={{ width: "100%", display: "block" }}>{item.service_name}</div>
																				</Text>
																				<Flex justify='space-between' key={index}>
																					<div style={{ padding: "5px" }}>{item.amount}</div>
																					<div style={{ padding: "0px" }}>
																						<Text>
																							{item.discount != 0 ?
																								item.discountPercent ? item.discountPercent + "%" :
																									("-" + formatMoney(item.discount, 0) + "đ")
																								: null}
																						</Text>
																					</div>
																					<div style={{ padding: "0px" }} >{formatMoney(item.total, 0)}đ</div>
																				</Flex>
																				<hr />
																			</div>)
																		: null}
																</div>

																<Flex justify='space-between' mb='37px'>
																	<Text >Tổng tiền</Text>
																	<Text as='b'><Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>
																		{formatMoney(detail && detail.total, 0)} vnd
																	</Text></Text>
																</Flex>
															</Box>
														</Box>
													</div>

													<Center>
														<Text mb='37px' as="cite">Trao trọn giá trị cho vẻ đẹp làn da của bạn</Text>
													</Center>
												</Box>
											</Box>
											<br />
											<Box display={{ sm: 'block', xl: 'none' }}>
												<div style={{ textAlign: 'right' }}>
													<Button colorScheme='blue' onClick={() => onButtonClick(`${detail ? detail.customer_username : "Tên"}-${detail && detail.customer_phone_number ? detail.customer_phone_number : "SDT"}-${formatDate(detail && detail.create_date)}`)}><Icon as={MdPrint} />&ensp; In hoá đơn</Button>
												</div>
											</Box>
										</Box>
									</ModalBody>
								</Box>
								<Box display={{ sm: 'none', xl: 'block' }}>
									<Box rounded="md" boxShadow='md' h="100%" style={{
										position: "relative",
										overflow: "hidden",
										boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between'
									}}
									>
										<ModalHeader>
											Đã xuất!
										</ModalHeader>
										<ModalCloseButton />
										<div>
											<SimpleGrid
												mb='20px'
												columns={{ sm: 1, md: 2 }}
												spacing={{ base: "20px", xl: "20px" }}
											>
												<div style={{ padding: "20px 20px 0 20px" }}>
													<img src={logobill} style={{ width: '70%' }} />
												</div>
												<div style={{ textAlign: "right", padding: "80px 40px 0 0" }}>
													<Text fontSize='3xl' fontWeight='700'>HOÁ ĐƠN</Text>
													<Text color='grey.300' >
														<Icon as={MdCalendarToday} />{formatDate(detail && detail.create_date)}
													</Text>
												</div>
											</SimpleGrid>
											<Box p="0px 20px">
												<SimpleGrid
													mb='20px'
													columns={{ sm: 1, md: 2 }}
													spacing={{ base: "20px", xl: "20px" }}
													bg="gray.200"
													p="20px 20px"
												>
													<Box>
														<Text fontSize='xl' fontWeight='700'>Khách hàng</Text>
														<Text fontSize='md'>{detail && detail.customer_userid && detail.customer_userid.name} - {detail && detail.customer_userid && detail.customer_userid.phone_number}</Text>
														<Text fontSize='md'>{detail && detail.customer_userid && detail.customer_userid.address}</Text>
													</Box>
													<Box>
														<Text fontSize='xl' fontWeight='700'>Miêu tả</Text>
														<Text fontSize='md'>{detail && detail.description}</Text>
														<div style={{ display: 'flex', flexWrap: "wrap" }}>
															{detail && detail.isPaid == 'Chưa thanh toán' && <Badge variant='solid' colorScheme='red'>Chưa thanh toán</Badge>}
															{detail && detail.isPaid == 'Đã thanh toán' && <Badge variant='solid' colorScheme='green'>Đã thanh toán</Badge>}
															&ensp;
															<Text fontSize='md'>{detail && detail.noteWhenNotPaid ? detail.noteWhenNotPaid : ""}</Text>
														</div>
													</Box>
												</SimpleGrid>
												<Box overflowX={{ sm: 'scroll', lg: 'hidden' }}>
													<Table colorScheme='blackAlpha'>
														<Thead>
															<Tr>
																<Th style={{ padding: "5px", width: '30%' }} color="black" fontWeight='700'>Sản phẩm / dịch vụ</Th>
																<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Số lượng</Th>
																<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Đơn giá</Th>
																<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Giảm</Th>
																<Th style={{ padding: "5px" }} color="black" fontWeight='700' isNumeric>Thành tiền</Th>
															</Tr>
														</Thead>
														<Tbody>
															{detail && detail.service_list && detail.service_list.length > 0 ?
																detail.service_list.map((item: any, index: any) => <Tr key={index}>
																	<Td style={{ padding: "5px" }}>
																		<Text style={{ marginRight: '5px' }}>{item.service_name}</Text>
																	</Td>
																	<Td style={{ padding: "5px" }}>{item.amount}</Td>
																	<Td style={{ padding: "5px" }}>{formatMoney(item.cost, 0)}đ</Td>
																	<Td style={{ padding: "5px" }}>
																		<Text>
																			{/* {item.discount != 0 ? "-" + formatMoney(item.discount, 0) + "đ" : null} */}
																			{item.discount != 0 ?
																				item.discountPercent ? item.discountPercent + "%" :
																					("-" + formatMoney(item.discount, 0) + "đ")
																				: null}
																		</Text>
																	</Td>
																	<Td style={{ padding: "5px" }} isNumeric>{formatMoney(item.total, 0)}đ</Td>
																</Tr>)
																: null}
														</Tbody>
														<Tfoot>
															<Tr>
																<Th style={{ padding: "5px" }} color="black" fontWeight='700'>Tổng tiền</Th>
																<Th style={{ padding: "5px" }}></Th>
																<Th style={{ padding: "5px" }}></Th>
																<Th style={{ padding: "5px" }}></Th>
																<Th style={{ padding: "5px" }} isNumeric>
																	<Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>
																		{formatMoney(detail && detail.total, 0)} vnd
																	</Text>
																</Th>
															</Tr>
														</Tfoot>
													</Table>
												</Box>
											</Box>
										</div>

										<Center>
											<Text p="20px" as="cite">Trao trọn giá trị cho vẻ đẹp làn da của bạn</Text>
										</Center>
									</Box>
								</Box>
							</ModalContent>
						</Modal>
					</TabPanel>
					<TabPanel>
						<ColumnsTableNotPaid 
							title=""
							columnsData={columnsDataBill}
							tableData={listUserIsNotPaid ? listUserIsNotPaid : []}
							onClickDetail={onClickDetail}
							reload={reload}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>

		</Card >
	);
}
