// Chakra imports
import { Box, SimpleGrid, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ColumnsTableProduct from "./Product/ColumnsTable";
import ColumnsTableProductCategory from "./ProductCateogy/ColumnsTable";
import ColumnsTableService from "./Service/ColumnsTable";
import ColumnsTableServiceCategory from "./ServiceCategory/ColumnsTable";
import { columnsDataColumnsProduct, columnsDataColumnsCategory, columnsDataColumnsService } from "./constant";
import CardComponent from 'components/card/Card';
import { useEffect, useState } from "react";
import { getPostListCutomer, getPostListProductService, getPostListCategory } from "../../../store/client/actions/Thunk"
import { useDispatch } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr, TableContainer, Tfoot, Flex, Text, useColorModeValue, Badge, Avatar } from '@chakra-ui/react';
import { formatDate } from '../../../utils/commonLib'

export default function Customer() {

  const dispatch = useDispatch()

  const [listProduct, setListProduct] = useState(null)
  const [listProductFilter, setListProductFilter] = useState(null)
  const [listProductCategory, setListProductCategory] = useState(null)
  const [listProductCategoryFilter, setListProductCategoryFilter] = useState(null)

  const [listService, setListService] = useState(null)
  const [listServiceFilter, setListServiceFilter] = useState(null)
  const [listServiceCategory, setListServiceCategory] = useState(null)
  const [listServiceCategoryFilter, setListServiceCategoryFilter] = useState(null)

  const [listCategory, setListCategory] = useState(null)

  const [detail, setDetail] = useState(null)
  const [detailName, setDetailName] = useState(null)

  useEffect(() => {
    getDataProductService();
  }, [])


  const getDataProductService = async () => {
    const result: any = await dispatch(getPostListProductService())
    if (result && result.status == "SUCCESS") {
      const data = result.data.map((item: any, index: number) => {
        if (item.category_id && item.category_id.name) {
          item.category_name = item.category_id.name
        }
        return item
      })
      // setListProduct(data)

      const dataProduct = data.filter((item: any, index: any) => item.is_service == false)
      const newDataProduct = dataProduct.map((item: any, index: any) =>{
        if(item.created_userid && item.updated_userid){
          item.created_username = item.created_userid.name
          item.updated_username = item.updated_userid.name
        }
        return item
      })
      setListProduct(newDataProduct)

      const dataService = data.filter((item: any, index: any) => item.is_service == true)
      const newDataService = dataService.map((item: any, index: any) =>{
        if(item.created_userid && item.updated_userid){
          item.created_username = item.created_userid.name
          item.updated_username = item.updated_userid.name
        }
        return item
      })
      setListService(newDataService)

      getDataCategory()
    }
  };

  const reload = () => {
    getDataProductService()
  }

  const getDataCategory = async () => {
    const result: any = await dispatch(getPostListCategory())
    if (result && result.status == "SUCCESS") {
      const dataCategory = result.data
      setListCategory(dataCategory)

      const dataProductCategory = dataCategory.filter((item: any, index: any) => item.is_service == false)
      const newDataProductCategory = dataProductCategory.map((item: any, index: any) =>{
        if(item.created_userid && item.updated_userid){
          item.created_username = item.created_userid.name
          item.updated_username = item.updated_userid.name
        }
        return item
      })
      setListProductCategory(newDataProductCategory)

      const dataServiceCategory = dataCategory.filter((item: any, index: any) => item.is_service == true)
      const newDataServiceCategory = dataServiceCategory.map((item: any, index: any) =>{
        if(item.created_userid && item.updated_userid){
          item.created_username = item.created_userid.name
          item.updated_username = item.updated_userid.name
        }
        return item
      })
      setListServiceCategory(newDataServiceCategory)
    }
  };

  const onClickDetail = (name: any) => {
    setDetailName(name)
    if (listCategory && listCategory.length > 0) {
      let newListBill = listCategory.filter((item: { customer_username: any; }) => item.customer_username == name)
      if (newListBill && newListBill.length > 0) {
        return setDetail([...newListBill])
      }
      setDetail([])
    } else {
      getDataCategory()
    }
  }
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const filterProduct = async (e: any) => {
    if (e.target.value && e.target.value != "" && listProduct && listProduct.length > 0) {
      return setListProductFilter(listProduct.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    setListProductFilter([])
  }

  const filterProductCategory = async (e: any) => {
    if (e.target.value && e.target.value != "" && listProductCategory && listProductCategory.length > 0) {
      return setListProductCategoryFilter(listProductCategory.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    setListProductCategoryFilter([])
  }

  const filterService = async (e: any) => {
    if (e.target.value && e.target.value != "" && listService && listService.length > 0) {
      return setListServiceFilter(listService.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    setListServiceFilter([])
  }

  const filterServiceCategory = async (e: any) => {
    if (e.target.value && e.target.value != "" && listServiceCategory && listServiceCategory.length > 0) {
      return setListServiceCategoryFilter(listServiceCategory.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    setListServiceCategoryFilter([])
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}> */}

      <Tabs variant='soft-rounded' colorScheme='blue'>
        <TabList>
          <Tab>Sản phẩn</Tab>
          <Tab>Phân loại sản phẩm</Tab>
          <Tab>Dịch vụ</Tab>
          <Tab>Phân loại dịch vụ</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ColumnsTableProduct
              title="Sản phẩm"
              listCategory={listProductCategory ? listProductCategory : []}
              columnsData={columnsDataColumnsProduct}
              // tableData={listProduct ? listProduct : []}
              tableData={
                listProductFilter && listProductFilter.length > 0 ?
                listProductFilter :
                listProduct ? listProduct : []
              }
              reload={reload}
              filterProduct={filterProduct}
            />
          </TabPanel>
          <TabPanel>
            <ColumnsTableProductCategory
              title="Phân loại sản phẩm"
              listCategory={listCategory ? listCategory : []}
              columnsData={columnsDataColumnsCategory}
              // tableData={listProductCategory ? listProductCategory : []}
              tableData={
                listProductCategoryFilter && listProductCategoryFilter.length > 0 ?
                listProductCategoryFilter :
                listProductCategory ? listProductCategory : []
              }
              onClickDetail={onClickDetail}
              reload={reload}
              filterProductCategory={filterProductCategory}
            />
          </TabPanel>
          <TabPanel>
            <ColumnsTableService
              title="Dịch vụ"
              listCategory={listServiceCategory ? listServiceCategory : []}
              columnsData={columnsDataColumnsService}
              // tableData={listService ? listService : []}
              tableData={
                listServiceFilter && listServiceFilter.length > 0 ?
                listServiceFilter :
                listService ? listService : []
              }
              onClickDetail={onClickDetail}
              reload={reload}
              filterService={filterService}
            />
          </TabPanel>
          <TabPanel>
            <ColumnsTableServiceCategory
              title="Phân loại dịch vụ"
              listCategory={listCategory ? listCategory : []}
              columnsData={columnsDataColumnsCategory}
              // tableData={listServiceCategory ? listServiceCategory : []}
              tableData={
                listServiceCategoryFilter && listServiceCategoryFilter.length > 0 ?
                listServiceCategoryFilter :
                listServiceCategory ? listServiceCategory : []
              }
              onClickDetail={onClickDetail}
              reload={reload}
              filterServiceCategory={filterServiceCategory}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* <ColumnsTableProduct
          title="Sản phẩm"
          listCategory={listProductCategory?listProductCategory:[]}
          columnsData={columnsDataColumnsProduct}
          tableData={listProduct ? listProduct : []}
          reload={reload}
        />
        <ColumnsTableProductCategory
          title="Phân loại sản phẩm"
          listCategory={listCategory?listCategory:[]}
          columnsData={columnsDataColumnsCategory}
          tableData={listProductCategory ? listProductCategory : []}
          onClickDetail={onClickDetail}
          reload={reload}
        />
        <ColumnsTableService
          title="Dịch vụ"
          listCategory={listServiceCategory?listServiceCategory:[]}
          columnsData={columnsDataColumnsService}
          tableData={listService ? listService : []}
          onClickDetail={onClickDetail}
          reload={reload}
        />
        <ColumnsTableServiceCategory
          title="Phân loại dịch vụ"
          listCategory={listCategory?listCategory:[]}
          columnsData={columnsDataColumnsCategory}
          tableData={listServiceCategory ? listServiceCategory : []}
          onClickDetail={onClickDetail}
          reload={reload}
        /> */}
      {/* </SimpleGrid> */}
    </Box>
  );
}
