export const columnsDataCheck = [
  {
    Header: "Tên",
    accessor: "name",
  },
  {
    Header: "Tiến trình",
    accessor: "progress",
  },
  {
    Header: "Số lượng",
    accessor: "quantity",
  },
  {
    Header: "Ngày",
    accessor: "date",
  },
];
export const columnsDataRegularCustomer = [
  {
    Header: "STT",
    accessor: "stt",
  },
  {
    Header: "Tên",
    accessor: "name",
  },
  {
    Header: "Số lần đến",
    accessor: "visited",
  },
];
export const columnsDataPotentialCustomer = [
  {
    Header: "STT",
    accessor: "stt",
  },
  {
    Header: "Tên",
    accessor: "name",
  },
  {
    Header: "Tổng chi",
    accessor: "total",
  },
];
export const columnsDataComplex = [
  {
    Header: "Tên",
    accessor: "name",
  },
  {
    Header: "Tình trạng",
    accessor: "status",
  },
  {
    Header: "Ngày",
    accessor: "date",
  },
  {
    Header: "Tiến trình",
    accessor: "progress",
  },
];
