import React from 'react'
import { Spinner } from '@chakra-ui/react'

const styles = {
  position: 'fixed',
  top: '0',
  left: '0',
  backgroundColor: '#21252940',
  zIndex: '999',

  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default function SpinOverlay(props) {
  return (
    <>
      {props.isRequest ?
        <div style={styles}>
          <Spinner
            thickness='4px'
            speed='1s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </div> : ''
      }
    </>

  )
}
