import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    return <Route
        {...rest}
        render={routeProps => (
            isAuthenticated ? 
                <Component {...routeProps} />
            : <Redirect to={{
                pathname: '/auth/sign-in'
            }} />
        )}
    />;
}



export default PrivateRoute