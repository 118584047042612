import axios from "axios";
import { Dispatch } from "redux";
import apiClient from "../../../utils/requestAPI";
import { Action } from "./index";
import { Post, PostLogin, PostCustomer, PostProduct, PostCategory, PostBill, IsPaidUpdateBill } from "./Model";
import ActionType from "./Type";

// default example getPosts START
export const getPosts = () => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({ type: ActionType.GET_POST_REQUEST });

    const { data } = await axios.get<Post[]>(
      "https://jsonplaceholder.typicode.com/posts?_limit=10"
    );

    // dispatch({
    //   type: ActionType.GET_POST_SUCCESS,
    //   payload: data,
    // });

  } catch (error: any) {
    dispatch({
      type: ActionType.GET_POST_FAIL,
      payload: error.message,
    });
  }
};
// default example getPosts END

export const getPostListCutomer = () => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const {data} = await apiClient().get("/getAllCustomer")
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};


export const getPostListBill = () => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const {data} = await apiClient().get("/GetAllBill")
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsUpdateIsPaidBill = (post : IsPaidUpdateBill) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/UpdateBill", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};


export const TotalRevenue = () => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const {data} = await apiClient().get("/TotalRevenue")
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostListProductService = () => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const {data} = await apiClient().get("/getAllProduct")
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostListCutomerBill = () => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const {data} = await apiClient().get("/GetAllBill")
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostListCategory = () => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const {data} = await apiClient().get("/getAllCategory")
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsLogin = (post : PostLogin) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/signin", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS_LOGIN, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    console.log(":error",error)
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsCreateBill = (post : PostBill) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/AddBill", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsAddCUstomer = (post : PostCustomer) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/addCustomer", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsUpdateCustomer = (post : any) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/updateCustomer", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsAddProduct = (post : PostProduct) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/insertNewProduct", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsUpdateProduct = (post : any) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/updateProduct", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export const getPostsAddCategory = (post : PostCategory) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/insertNewCategory", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};
export const getPostsUpdateCategory = (post : any) => async (dispatch: Dispatch<Action>) => {
  try {

    dispatch({ type: ActionType.GET_POST_REQUEST });
    const { data } = await apiClient().post("/updateCategory", post)
    dispatch({ type: ActionType.GET_POST_SUCCESS, payload: data });
    return data

  } catch (error: any) {

    const {response} = error
    dispatch({ type: ActionType.GET_POST_FAIL, payload: response && response?.data && response?.data.message ? response?.data.message : "Có lỗi trong quá trình lấy dữ liệu" });
    return response?.data

  }
};

export function logOut()
{
    localStorage.removeItem('LoginInfo')
    return {
        type: ActionType.LOGOUT
    };
}