import React from 'react'
import { useSelector as UseSelector } from '../../hooks/useTypedSelector'
import ModalNotify from './modal/ModalConfirm.js'

const index = () => {

  const { posts } = UseSelector((state) => state)

  return (
    <>
      {posts.loading == false ? <ModalNotify isError={posts.isError} description={posts.isError ? posts.error : posts.data} /> : ""}
    </>
  )
}

export default index