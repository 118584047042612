import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import Views from './views/index'
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { Provider } from "react-redux";

import { store } from "./store";

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
		<Provider store={store}>
			<Views/>
		</Provider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
