

// Chakra imports
import {
	Icon,
	Flex,
	Text,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useDisclosure,
	useColorModeValue,


	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,


	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Select,
	SimpleGrid,
	Center,
	Code,
	Box
} from '@chakra-ui/react';
// Assets
import { MdOutlinePersonAddAlt, MdPlaylistAdd } from 'react-icons/md';
import { formatDate, formatMoney } from '../../../../utils/commonLib'
import { useState } from 'react'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux'
import { getPostsAddProduct } from 'store/client/actions/Thunk';
export default function Banner(props: { [x: string]: any, reload: any, listCategory: any }) {
	const { reload, listCategory } = props;

	const dispatch = useDispatch()

	const [showCost, setShowCost] = useState("")
	const [showRealCost, setRealShowCost] = useState("")

	// Ellipsis modals
	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
	const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
	const min = 'Ít nhất 3 kí tự.'
	const max = 'Giới hạn 50 kí tự.'
	const required = 'Trường thông tin bắt buộc.'

	const addNewCustomerSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, min)
			.max(50, max)
			.required(required),
		detailed_description: Yup.string()
			.min(2, min)
			.max(100, max)
			.required(required),
		cost: Yup.number()
			.typeError('Vui lòng nhập số.')
			.min(100, "Số tiền ít nhất là 100đ")
			.required(required),
		real_cost: Yup.number()
			.typeError('Vui lòng nhập số.')
			.min(100, "Số tiền ít nhất là 100đ")
			.required(required),
		type: Yup.string()
			.required(required),
	});

	return (
		<Menu isOpen={isOpen1} onClose={onClose1}>
			<Button leftIcon={<MdPlaylistAdd />} colorScheme='blue' variant='solid' size='sm' onClick={onOpen2}>
				Thêm
			</Button>
			<Modal closeOnOverlayClick={false} isOpen={isOpen2} onClose={onClose2} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Thêm mới dịch vụ</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Formik
							initialValues={{
								name: '',
								type: '',
								detailed_description: '',
								cost: '',
								real_cost: '',
							}}
							validationSchema={addNewCustomerSchema}
							onSubmit={async values => {
								onClose2()
								const newValue: any = { ...values }
								newValue.is_service = true
								const tokenLocal = localStorage.getItem("LoginInfo")
								if (tokenLocal) {
									let id = ''
									const tokenParse = JSON.parse(tokenLocal)
									id = tokenParse.data[0]._id
									newValue.created_userid = id
									const result: any = await dispatch(getPostsAddProduct(newValue))
									if (result && result.status === "SUCCESS") {
										reload()
									}
								}
							}}
						>
							{({ errors, touched }) => (
								<Form>
									<Field name="name">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.name && touched.name}>
												<FormLabel>Tên dịch vụ</FormLabel>
												<Input {...field} placeholder='Tên dịch vụ' />
												<FormErrorMessage>{errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<Field name="type">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.type && touched.type}>
												<FormLabel>Loại dịch vụ</FormLabel>
												<Select {...field} placeholder='Loại dịch vụ'>
													{listCategory && listCategory.length > 0 ?
														listCategory.map((item: any, index: any) => <option key={index} value={item.name}>{item.name} - {item.description}</option>)
														: ""
													}
												</Select>
												<FormErrorMessage>{errors.type}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<Field name="detailed_description">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.detailed_description && touched.detailed_description}>
												<FormLabel>Mô tả chi tiết</FormLabel>
												<Input {...field} placeholder='Mô tả chi tiết' />
												<FormErrorMessage>{errors.detailed_description}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br />
									<SimpleGrid columns={2}>
										<Field name="cost">
											{({ field, form }: any) => (
												<FormControl isInvalid={errors.cost && touched.cost}>
													<FormLabel>Giá</FormLabel>
													<Input {...field} placeholder='Giá' onKeyUp={(e: any) => setShowCost(e.target.value)} />
													<FormErrorMessage>{errors.cost}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<Flex direction={'column'} justifyContent={'flex-end'} pb={5}>
											<Box><Code bg="yellow">{formatMoney(showCost, 0)} VND</Code></Box>
										</Flex>
									</SimpleGrid><br />
									<SimpleGrid columns={2}>
										<Field name="real_cost">
											{({ field, form }: any) => (
												<FormControl isInvalid={errors.real_cost && touched.real_cost}>
													<FormLabel>Thực giá</FormLabel>
													<Input {...field} placeholder='Thực giá' onKeyUp={(e: any) => setRealShowCost(e.target.value)} />
													<FormErrorMessage>{errors.real_cost}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<Flex direction={'column'} justifyContent={'flex-end'} pb={5}>
											<Box><Code bg="yellow">{formatMoney(showRealCost, 0)} VND</Code></Box>
										</Flex>
									</SimpleGrid>
									<div style={{ textAlign: 'right' }}>
										<div>
											<Button
												mt={4}
												mr={3}
												colorScheme='blue'
												isLoading={props.isSubmitting}
												type='submit'
											>
												Cập nhật
											</Button>
											<Button
												mt={4}
												colorScheme='blue'
												variant='outline'
												onClick={onClose2}
											>
												Thoát
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Menu>
	);
}
