import { Action } from "../actions/index";
import { Post } from "../actions/Model";
import ActionType from "../actions/Type";
interface PostState {
  loading: boolean;
  isError: boolean | null;
  error: string | null;
  data: object | null | string;

  IsLoginSuccess: false | null;
}

const initialState: PostState = { isError: null, loading: false, error: null, data: null, IsLoginSuccess: false };

export const postReducer = (state: PostState = initialState, action: Action): PostState => {
  switch (action.type) {
    case ActionType.GET_POST_REQUEST:
      return Object.assign({}, state, { loading: true, error: null, data: null });

    case ActionType.GET_POST_SUCCESS:
      return Object.assign({}, state, { loading: false, isError: false, error: null, data: action.payload.message, IsLoginSuccess: true });
    case ActionType.GET_POST_SUCCESS_LOGIN:
      localStorage.setItem('LoginInfo', JSON.stringify(action.payload))

      return Object.assign({}, state, { loading: false, isError: false, error: null, data: action.payload.message, IsLoginSuccess: true });

    case ActionType.GET_POST_FAIL:
      return Object.assign({}, state, { loading: false, isError: true, error: action.payload, data: null });

    case ActionType.LOGOUT:
      return Object.assign({}, state, { loading: false, error: null, data: null, isError: null, IsLoginSuccess: false });

    default:
      return state;
  }
};
