import { Flex, Table, Checkbox, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Button, Icon, color } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdSearch } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';

import Reward from 'assets/img/reward/Reward' 
export default function RegularCustomer(props: { columnsData: any; tableData: any }) {
	const { columnsData, tableData } = props;

	const columns = useMemo(() => columnsData, [ columnsData ]);
	const data = useMemo(() => tableData, [ tableData ]);

	let tableInstance = useTable(
		{
			columns,
			data
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const styleSelect = {
		padding: "5px",
		border: "1px solid #80808082",
		borderRadius: '10px'
	}

	const { getTableProps, getTableBodyProps, pageOptions, setPageSize, headerGroups, page, prepareRow, initialState, gotoPage, canPreviousPage, previousPage, nextPage, canNextPage, pageCount, state: { pageIndex, pageSize } } = tableInstance;
	initialState.pageSize = 5;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb='2' justify='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Top khách hàng đến thường xuyên
				</Text>
				{/* <Menu /> */}
			</Flex>
			<Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{headerGroups.map((headerGroup: any, index: number) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map(
								(
									column: {
										render(a: string): JSX.Element;
										getHeaderProps(a: any): any;
										getSortByToggleProps(): any;
									},
									index: number
								) => (
									<Th
										{...column.getHeaderProps(column.getSortByToggleProps())}
										pe='10px'
										key={index}
										fontWeight='700'
										borderColor={borderColor}>
										<Flex
											justify='space-between'
											align='center'
											fontSize={{ sm: '10px', lg: '12px' }}
											color='gray.400'>
											{column.render('Header')}
										</Flex>
									</Th>
								)
							)}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row: any, index: number) => {
						prepareRow(row);
						return (
							<Tr {...row.getRowProps()} key={index}>
								{row.cells.map((cell: any, index: number) => {
									let data;
									if (cell.column.Header === 'STT') {
										data = (
											<Flex align='center'>
												{cell.value !== 1 && cell.value !== 2 && cell.value !== 3 ? 
													<Text color={textColor} fontSize='sm' pl='4'>
														{cell.value}
													</Text>
												: ""}
												
												{cell.value === 1 ? <div><Reward color='hsl(48,100%,50%)' /></div> : ""}
												{cell.value === 2 ? <div><Reward color='hsl(210,6%,72%)' /> </div>: ""}
												{cell.value === 3 ? <div><Reward color='hsl(28,38%,67%)' /> </div> : ""}
											</Flex>
										);
									} 
									else if (cell.column.Header === 'Tên') {
										data = (
											<Flex align='center'>
												<Text color={textColor} fontSize='md' >
													{cell.value.toUpperCase()}
												</Text>
											</Flex>
										);
									} 
									else if (cell.column.Header === 'Số lần đến') {
										data = (
											<Flex align='center'>
												<Text me='10px' color={textColor} fontSize='md' >
													{cell.value}
												</Text>
											</Flex>
										);
									} 
									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'
										>
											{data}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Flex justify='space-between' align='center' px='37px'>
				<Flex>
					{/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						{"<<"}
					</button>{" "} */}
					<Button colorScheme='teal' size='small' onClick={() => previousPage()} disabled={!canPreviousPage}>
						<Icon as={MdKeyboardArrowLeft} width='30px' height='30px' color='inherit' />
					</Button>&ensp;
					<span style={{ padding: '3px 5px 0 0' }}>
						<strong>
							{pageIndex + 1} / {pageOptions.length}
						</strong>{" "}
					</span>
					<Button colorScheme='teal' size='small' onClick={() => nextPage()} disabled={!canNextPage}>
						<Icon as={MdKeyboardArrowRight} width='30px' height='30px' color='inherit' />
					</Button>&ensp;
					{/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						{">>"}
					</button>{" "} */}
					{/* <span style={{ padding: '3px 5px 0 0' }}>
						<Flex>
							<div>
								| Đi đến trang:&ensp;
							</div>

							<span><NumberInput size='xs' maxW={16} defaultValue={pageIndex + 1} min={1} max={pageOptions.length}>
								<NumberInputField onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									gotoPage(page);
								}} />
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput></span>
						</Flex>
					</span>{" "} */}
				</Flex>


				<select
					style={styleSelect}
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
				>
					{[5, 10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							{pageSize}
						</option>
					))}
				</select>
			</Flex>
		</Card>
	);
}
