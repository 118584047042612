// Chakra imports
import { Avatar, Box, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy } from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
import RegularCustomer from 'views/admin/default/components/RegularCustomer';
import PotentialCustomer from 'views/admin/default/components/PotentialCustomer';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import { columnsDataRegularCustomer, columnsDataPotentialCustomer } from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';

import { useDispatch } from 'react-redux'
import { TotalRevenue } from "../../../store/client/actions/Thunk"

import { useState, useEffect } from 'react';
import { formatMoney } from 'utils/commonLib';

export default function UserReports() {
	// Chakra Color Mode
	const brandColorGreen = useColorModeValue('green', 'white');
	const boxBgGreen = useColorModeValue('green.300', 'whiteAlpha.100');

	const brandColorRed = useColorModeValue('red', 'white');
	const boxBgRed = useColorModeValue('red.200', 'whiteAlpha.100');

	const brandColorYellow = useColorModeValue('yellow.500', 'white');
	const boxBgYellow = useColorModeValue('yellow.100', 'whiteAlpha.100');

	const dispatch = useDispatch();

	const [ totalRevenue, setTotalRevenue]:any = useState(null)

	const getDataCustomer = async () => {
		const result: any = await dispatch(TotalRevenue())
		if (result && result.status == "SUCCESS") {
			let clonebill_user_regular = JSON.parse(JSON.stringify(result))
			let clonebill_user_potential = JSON.parse(JSON.stringify(result))
			const cloneList_regular_customer = clonebill_user_regular.list_regular_customer.map((item:any, index:any) => {
				return {
					stt: index+1,
					name: item.customer_userid.name,
					phone_number: item.customer_userid.phone_number,
					address: item.customer_userid.address,
					sex: item.customer_userid.sex ? "Nữ" : "Nam",
					visited: item.visited
				}
			})
			const cloneList_potential_customer = clonebill_user_potential.list_regular_potential.map((item:any, index:any) => {
				return {
					stt: index+1,
					name: item.customer_userid.name,
					phone_number: item.customer_userid.phone_number,
					address: item.customer_userid.address,
					sex: item.customer_userid.sex ? "Nữ" : "Nam",
					total: item.total
				}
			})
			const newResult = {
				...result,
				list_regular_customer: cloneList_regular_customer,
				list_potential_customer: cloneList_potential_customer,
			} 
			setTotalRevenue(newResult)
		}
	}

	useEffect(()=>{
		getDataCustomer()
	},[])
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBgGreen}
							icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColorGreen} />}
						/>
					}
					bg='green.100'
					name='Tổng doanh thu'
					value={totalRevenue? `${formatMoney(totalRevenue.sum,0)} VND` : ''}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBgRed}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColorRed} />}
						/>
					}
					bg='red.100'
					name='Ghi nợ'
					value={totalRevenue? `${formatMoney(totalRevenue.unpaid,0)} VND` : ''}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBgYellow}
							icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColorYellow} />}
						/>
					}
					bg='yellow.50'
					name='Tổng số hoá đơn đã xuất'
					value={`${totalRevenue && totalRevenue.count ? totalRevenue.count : 0} hoá đơn`}
				/>
				
			</SimpleGrid>

			{/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
				<TotalSpent />
				<WeeklyRevenue />
			</SimpleGrid> */}
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				{totalRevenue && totalRevenue.list_regular_customer ? <RegularCustomer columnsData={columnsDataRegularCustomer} tableData={totalRevenue.list_regular_customer} /> : ""}
				{totalRevenue && totalRevenue.list_potential_customer ? <PotentialCustomer columnsData={columnsDataPotentialCustomer} tableData={totalRevenue.list_potential_customer} /> : ""}

				{/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<DailyTraffic />
					<PieCard />
				</SimpleGrid> */}
			</SimpleGrid>
			{/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<ComplexTable columnsData={columnsDataComplex} tableData={tableDataComplex} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<Tasks />
					<MiniCalendar h='100%' minW='100%' selectRange={false} />
				</SimpleGrid>
			</SimpleGrid> */}
		</Box>
	);
}
