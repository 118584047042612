// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import ColumnsTable from "./ColumnsTable";
import { columnsDataColumnsCustomer, columnsDataColumnsBill } from "./constant";
import CardComponent from 'components/card/Card';
import { useEffect, useState } from "react";
import { getPostListCutomer, getPostListCutomerBill } from "../../../store/client/actions/Thunk"
import { useDispatch } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr, TableContainer, Tfoot, Flex, Text, useColorModeValue, Badge, Avatar } from '@chakra-ui/react';
import { formatDate, formatMoney } from '../../../utils/commonLib'

export default function Customer() {

  const dispatch = useDispatch()
  const [listCustomer, setListCustomer] = useState(null)
  const [listCustomerFilter, setListCustomerFilter] = useState(null)

  const [listCustomerBill, setListCustomerBill] = useState(null)
  const [detail, setDetail] = useState(null)
  const [detailName, setDetailName] = useState(null)

  useEffect(() => {
    getDataCustomer();
  }, [])


  const getDataCustomer = async () => {
    const result: any = await dispatch(getPostListCutomer())
    if (result && result.status == "SUCCESS") {
      const data = result.data.map((item: any, index: number) => {
        if (item.sex == true) {
          item.sex = "Nữ"
        } else {
          item.sex = "Nam"
        }
        return item
      })
      setListCustomer(data)
    }
  };

  const filterCustomer = async (e: any) => {
    if (e.target.value && e.target.value != "" && listCustomer && listCustomer.length > 0) {
      return setListCustomerFilter(listCustomer.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    setListCustomerFilter([])
  }

  const reload = () => {
    getDataCustomer()
  }

  const getDataCustomerBill = async (name: any) => {
    const result: any = await dispatch(getPostListCutomerBill())
    if (result && result.status == "SUCCESS") {
      const data = result.data.map((item: any, index: number) => {
        if (item.customer_userid) {
          item.customer_username = item.customer_userid.name
          item.customer_phoneNumber = item.customer_userid.phone_number
          item.customer_phone_number = item.customer_userid.phone_number
          if (item.customer_userid.sex) {
            item.sex = "Nữ"
          } else {
            item.sex = "Nam"
          }
        }
        if (item.created_userid) {
          item.created_username = item.created_userid.name
        }

        return item
      })
      setListCustomerBill(data)

      let newListBill = data.filter((item: { customer_phone_number: any; }) => item.customer_phone_number == name)
      if (newListBill && newListBill.length > 0) {
        return setDetail([...newListBill])
      }
      setDetail([])
    }
  };

  const onClickDetail = (name: any) => {
    setDetailName(name)
    if (listCustomerBill && listCustomerBill.length > 0) {
      let newListBill = listCustomerBill.filter((item: { customer_phone_number: any; }) => item.customer_phone_number == name)
      if (newListBill && newListBill.length > 0) {
        return setDetail([...newListBill])
      }
      setDetail([])
    } else {
      getDataCustomerBill(name)
    }
  }
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <ColumnsTable
          title="Danh sách khách hàng"
          columnsData={columnsDataColumnsCustomer}
          // tableData={listCustomer ? listCustomer : []}
          tableData={
            listCustomerFilter && listCustomerFilter.length > 0 ?
            listCustomerFilter :
            listCustomer ? listCustomer : []
          }
          filterCustomer={filterCustomer}
          onClickDetail={onClickDetail}
          reload={reload}
        />

        <CardComponent flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
          <TableContainer>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
              <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                {detail && detail.length > 0 ?
                  <Flex>
                    <Avatar name={detail[0].customer_username} bg="pink" />
                    <Box ml='3'>
                      <Text fontWeight='bold'>
                        {detail[0].customer_username}
                        <Badge ml='1' colorScheme='green'>
                          {detail[0].sex ? detail[0].sex : "Giới tính"}
                        </Badge>
                      </Text>
                      <Text fontSize='sm'>{detail[0].customer_phoneNumber ? detail[0].customer_phoneNumber : "Số điện thoại"}</Text>
                    </Box>
                  </Flex>
                  :
                  detail == null ?
                    <Badge fontSize='0.8em' colorScheme='green'>Chi tiết hoá đơn</Badge>
                    : <Badge fontSize='0.7em' colorScheme='red'>{detailName} - Không có dữ liệu </Badge>
                }
                {/* End */}
              </Text>
            </Flex>

            {detail && detail.map((item: any, index: any) => {
              return <div key={index}>
                <div style={{ marginLeft: '24px' }}>
                  <Text color={textColor} fontSize='20px' fontWeight='700' lineHeight='100%'>
                    Hoá đơn {index + 1} - {formatDate(item.create_date)}
                  </Text>
                  <Text as='i'>Ghi chú: {item.description}</Text>
                </div>
                <Table key={index} variant='striped' colorScheme='blackAlpha'>
                  <Thead>
                    <Tr>
                      <Th>Sản phẩm/dịch vụ</Th>
                      <Th>Số lượng</Th>
                      <Th>Giảm</Th>
                      <Th isNumeric>Thành tiền</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {item && item.service_list && item.service_list.map((item: any, index: any) => <Tr key={index}>
                      <Td>{item.service_name}</Td>
                      <Td>{item.amount}</Td>
                      <Td isNumeric>{formatMoney(item.discount, 0)} VND</Td>
                      <Td isNumeric>{formatMoney(item.total, 0)} VND</Td>
                    </Tr>)}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Td>Nhân viên tạo:</Td>
                      <Th>{item.created_username}</Th>
                      <Td>Ngày xuất:</Td>
                      <Th isNumeric>{formatDate(item.create_date)}</Th>
                    </Tr>
                    <Tr>
                      <Th>Tổng tiền</Th>
                      <Th></Th>
                      <Th></Th>
                      <Th isNumeric>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>
                          {formatMoney(item.total, 0)} VND
                        </Text>
                      </Th>
                    </Tr>
                  </Tfoot>
                </Table>
                <hr />
                <br />
                <br />
              </div>
            })}
          </TableContainer>
        </CardComponent>
      </SimpleGrid>
    </Box>
  );
}
