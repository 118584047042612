import React, { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

const ModalConfirm = (props) => {
    const toast = useToast()

    const { title, description, duration, isError, position } = props

    const descriptionDiv = <div style={{ width: '280px'}}>
        {description ? description : 'Mô tả'}
    </div>

    useEffect(() => {
        if(typeof isError == "boolean") {
            toast({
                title: title ? title : 'Thông báo',
                description: descriptionDiv,
                status: isError ? 'error' :'success',
                // duration: duration ? duration : 9000,
                duration: isError ? 9000 : 1000,
                isClosable: true,
                position: position ? position : 'bottom-right'
            })
        }
    },[])

    return (
        <></>
    )
}

export default ModalConfirm