// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

import illustration from "assets/img/logo/logodrlan.png";

const styles = {
	backgroundImage: 'linear-gradient(0deg, rgb(217, 175, 217) 0%, rgb(151, 217, 225) 100%)',
	padding: '20px',
	margin: '20px 40px 20px 20px', 
	borderRadius: '15px',
	boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
}

const styleImg = {
	margin: '0 auto',
}

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			{/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
			<div style={styles}>
				<img style={styleImg} src={illustration} width='60%'/>
			</div>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
