import { Icon } from '@chakra-ui/react';
import { MdBarChart, 
	// MdPerson, MdOutlineShoppingCart,
	MdHome, MdLock, 
 	MdCreditCard ,MdSupervisorAccount } from 'react-icons/md';

// Admin Imports
// import MainDashboard from 'views/admin/default';
// import NFTMarketplace from 'views/admin/marketplace';
// import Profile from 'views/admin/profile';
// import DataTables from 'views/admin/dataTables';
// import RTL from 'views/admin/rtl';

import MainDashboard from 'views/admin/default/Default';
import Customer from 'views/admin/Customer';
import ProductService from 'views/admin/ProductService';
import Invoice from 'views/admin/Invoice'

// Auth Imports
import SignInCentered from 'views/auth/signIn';

const routes = [
	{
		name: 'Bảng Thống Kê',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},

	{
		name: 'Thanh Toán - Hoá Đơn',
		layout: '/admin',
		icon: <Icon as={MdCreditCard} width='20px' height='20px' color='inherit' />,
		path: '/invoice',
		component: Invoice
	},
	{
		name: 'Khách Hàng',
		layout: '/admin',
		icon: <Icon as={MdSupervisorAccount} width='20px' height='20px' color='inherit' />,
		path: '/customer',
		component: Customer
	},
	{
		name: 'Sản Phẩm - Dịch Vụ',
		layout: '/admin',
		icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
		path: '/productservice',
		component: ProductService
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered
	},
	// {
	// 	name: 'Khách hàng',
	// 	layout: '/admin',
	// 	path: '/nft-marketplace',
	// 	icon: <Icon as={MdSupervisorAccount} width='20px' height='20px' color='inherit' />,
	// 	component: NFTMarketplace,
	// 	secondary: true
	// },
	// {
	// 	name: 'Sản phẩm - dịch vụ',
	// 	layout: '/admin',
	// 	icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
	// 	path: '/data-tables',
	// 	component: DataTables
	// },
	// {
	// 	name: 'Hồ sơ',
	// 	layout: '/admin',
	// 	path: '/profile',
	// 	icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
	// 	component: Profile
	// },
	// {
	// 	name: 'Đổi bên bảng điều khiển',
	// 	layout: '/rtl',
	// 	path: '/rtl-default',
	// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	// 	component: RTL
	// }
];

export default routes;
