

// Chakra imports
import {
	Icon,
	Flex,
	Text,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useDisclosure,
	useColorModeValue,


	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,


	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Select,
} from '@chakra-ui/react';
// Assets
import { MdOutlinePersonAddAlt } from 'react-icons/md';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux'
import { getPostsAddCUstomer } from 'store/client/actions/Thunk';
export default function Banner(props: { [x: string]: any, reload:any }) {
	const { reload } = props;

	const dispatch = useDispatch()

	// Ellipsis modals
	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
	const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
	const min = 'Ít nhất 3 kí tự.'
	const max = 'Giới hạn 50 kí tự.'
	const required = 'Trường thông tin bắt buộc.'

	const addNewCustomerSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, min)
			.max(50, max)
			.required(required),
		address: Yup.string()
			.min(2, min)
			.max(100, max)
			.required(required),
		phone_number: Yup.string().trim().matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, 'Số điện thoại không hợp lệ').required(required),
		sex: Yup.number().test('is boolean',
			'Please enter either 1 or 0',
			(value) => value === 0 || value === 1)
	});

	return (
		<Menu isOpen={isOpen1} onClose={onClose1}>
			<Button leftIcon={<MdOutlinePersonAddAlt />} colorScheme='blue' variant='solid' size='sm' onClick={onOpen2}>
				Thêm
			</Button>
			<Modal closeOnOverlayClick={false} isOpen={isOpen2} onClose={onClose2} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Thêm mới khách hàng</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Formik
							initialValues={{
								name: '',
								address: '',
								phone_number: '',
								sex: ''
							}}
							validationSchema={addNewCustomerSchema}
							onSubmit={async values => {
								onClose2()
								const newValue: any = { ...values }
								if (newValue.sex == "1") { // nữ
									newValue.sex = true;
								} else {
									newValue.sex = false;
								}
								const tokenLocal = localStorage.getItem("LoginInfo")
								if(tokenLocal){
									let id = ''
									const tokenParse = JSON.parse(tokenLocal)
									id = tokenParse.data[0]._id
									newValue.created_userid = id
									const result:any = await dispatch(getPostsAddCUstomer(newValue))
									if(result && result.status === "SUCCESS"){
										reload()
									}
								}
							}}
						>
							{({ errors, touched }) => (
								<Form>
									<Field name="name">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.name && touched.name}>
												<FormLabel>Họ và tên</FormLabel>
												<Input {...field} placeholder='Họ và tên' />
												<FormErrorMessage>{errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br/>
									<Field name="sex">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.sex && touched.sex}>
												<FormLabel>Giới tính</FormLabel>
												<Select {...field} placeholder='Giới tính'>
													<option value={0}>Nam</option>
													<option value={1}>Nữ</option>
												</Select>
												<FormErrorMessage>{errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br/>
									<Field name="address">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.address && touched.address}>
												<FormLabel>Địa chỉ</FormLabel>
												<Input {...field} placeholder='Địa chỉ' />
												<FormErrorMessage>{errors.address}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br/>
									<Field name="phone_number">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.phone_number && touched.phone_number}>
												<FormLabel>Số điện thoại</FormLabel>
												<Input {...field} placeholder='Số điện thoại' />
												<FormErrorMessage>{errors.phone_number}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br/>
									<div style={{ textAlign: 'right' }}>
										<div>
											<Button
												mt={4}
												mr={3}
												colorScheme='blue'
												isLoading={props.isSubmitting}
												type='submit'
											>
												Cập nhật
											</Button>
											<Button
												mt={4}
												colorScheme='blue'
												variant='outline'
												onClick={onClose2}
											>
												Thoát
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Menu>
	);
}
