// Chakra imports
import { Box, SimpleGrid, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ColumnsTable from "./ColumnsTable";
import { columnsDataBill, columnsDataColumnsBill } from "./constant";
import CardComponent from 'components/card/Card';
import { useEffect, useState } from "react";
import { getPostListBill, getPostListCutomerBill, getPostListProductService } from "../../../store/client/actions/Thunk"
import { useDispatch } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr, TableContainer, Tfoot, Flex, Text, useColorModeValue, Badge, Avatar } from '@chakra-ui/react';
import { formatDate } from '../../../utils/commonLib'
export default function Customer() {

  const dispatch = useDispatch()
  const [listBill, setListBill] = useState(null)
  const [listUserIsNotPaid, setListUserIsNotPaid] = useState(null)
  const [listCustomerBill, setListCustomerBill] = useState(null)
  const [detail, setDetail] = useState(null)
  const [detailName, setDetailName] = useState(null)

  // useEffect(() => {
  //   getDataBill();
  // }, [])


  const getDataBill = async () => {
    const result: any = await dispatch(getPostListBill())
    if (result && result.status == "SUCCESS") {
      const data = result.data.map((item: any, index: number) => {
        if (item.customer_userid) {
          item.customer_username = item.customer_userid.name
          if (item.customer_userid.sex) {
            item.customer_sex = 'Nữ'
          } else {
            item.customer_sex = 'Nam'
          }
          item.customer_phone_number = item.customer_userid.phone_number
          if (item.customer_userid.sex) {
            item.sex = "Nữ"
          } else {
            item.sex = "Nam"
          }
        }
        if (item.isPaid) {
          item.isPaid = "Đã thanh toán"
        } else if (item.isPaid == false) {
          item.isPaid = "Chưa thanh toán"
        }
        if (item.created_userid) {
          item.created_username = item.created_userid.name
        }
        return item
      })

      const dataUserIsNotPaid = JSON.parse(JSON.stringify(data))

      const dataUserIsNotPaidnew=  dataUserIsNotPaid.filter((bill:any) => bill.isPaid == "Chưa thanh toán")

      setListUserIsNotPaid(dataUserIsNotPaidnew)
      setListBill(data)
    }
  };

  const reload = () => {
    getDataBill()
  }

  const getDataBillBill = async (name: any) => {
    const result: any = await dispatch(getPostListCutomerBill())
    if (result && result.status == "SUCCESS") {
      const data = result.data.map((item: any, index: number) => {
        if (item.customer_userid) {
          item.customer_username = item.customer_userid.name
          if (item.customer_userid.sex) {
            item.customer_sex = 'Nữ'
          } else {
            item.customer_sex = 'Nam'
          }
          item.customer_phone_number = item.customer_userid.phone_number
          if (item.customer_userid.sex) {
            item.sex = "Nữ"
          } else {
            item.sex = "Nam"
          }
        }
        if (item.created_userid) {
          item.created_username = item.created_userid.name
        }

        return item
      })
      setListCustomerBill(data)

      let newListBill = data.filter((item: { customer_phone_number: any; }) => item.customer_phone_number == name)
      if (newListBill && newListBill.length > 0) {
        return setDetail([...newListBill])
      }
      setDetail([])
    }
  };

  const onClickDetail = (name: any) => {
    setDetailName(name)
    if (listCustomerBill && listCustomerBill.length > 0) {
      let newListBill = listCustomerBill.filter((item: { customer_phone_number: any; }) => item.customer_phone_number == name)
      if (newListBill && newListBill.length > 0) {
        return setDetail([...newListBill])
      }
      setDetail([])
    } else {
      getDataBillBill(name)
    }
  }
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ColumnsTable
        title="Danh sách hoá đơn"
        columnsData={columnsDataBill}
        listUserIsNotPaid={listUserIsNotPaid}
        tableData={listBill ? listBill : []}
        onClickDetail={onClickDetail}
        reload={reload}
      />
    </Box>
  );
}
