

// Chakra imports
import {
	Icon,
	Flex,
	Text,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useDisclosure,
	useColorModeValue,


	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,


	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Select,
} from '@chakra-ui/react';
// Assets
import { MdOutlinePersonAddAlt,MdPlaylistAdd } from 'react-icons/md';
import { formatDate, formatMoney } from '../../../../utils/commonLib'

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux'
import { getPostsAddCategory } from 'store/client/actions/Thunk';
export default function Banner(props: { [x: string]: any, reload:any, listCategory:any }) {
	const { reload, listCategory } = props;

	const dispatch = useDispatch()

	// Ellipsis modals
	const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
	const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
	const min = 'Ít nhất 3 kí tự.'
	const max = 'Giới hạn 50 kí tự.'
	const required = 'Trường thông tin bắt buộc.'

	const addNewCustomerSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, min)
			.max(50, max)
			.required(required),
		description: Yup.string()
			.min(2, min)
			.max(100, max)
			.required(required),
	});

	return (
		<Menu isOpen={isOpen1} onClose={onClose1}>
			<Button leftIcon={<MdPlaylistAdd />} colorScheme='blue' variant='solid' size='sm' onClick={onOpen2}>
				Thêm
			</Button>
			<Modal closeOnOverlayClick={false} isOpen={isOpen2} onClose={onClose2} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Thêm mới phân loại sản phẩm</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Formik
							initialValues={{
								name: '',
								description: ''
							}}
							validationSchema={addNewCustomerSchema}
							onSubmit={async values => {
								onClose2()
								const newValue: any = { ...values }
								newValue.is_service = false
								const tokenLocal = localStorage.getItem("LoginInfo")
								if(tokenLocal){
									let id = ''
									const tokenParse = JSON.parse(tokenLocal)
									id = tokenParse.data[0]._id
									newValue.created_userid = id
									const result:any = await dispatch(getPostsAddCategory(newValue))
									if(result && result.status === "SUCCESS"){
										reload()
									}
								}
							}}
						>
							{({ errors, touched }) => (
								<Form>
									<Field name="name">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.name && touched.name}>
												<FormLabel>Tên phân loại</FormLabel>
												<Input {...field} placeholder='Tên phân loại' />
												<FormErrorMessage>{errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br/>
									<Field name="description">
										{({ field, form }: any) => (
											<FormControl isInvalid={errors.description && touched.description}>
												<FormLabel>Mô tả chi tiết</FormLabel>
												<Input {...field} placeholder='Mô tả chi tiết' />
												<FormErrorMessage>{errors.description}</FormErrorMessage>
											</FormControl>
										)}
									</Field><br/>
									<div style={{ textAlign: 'right' }}>
										<div>
											<Button
												mt={4}
												mr={3}
												colorScheme='blue'
												isLoading={props.isSubmitting}
												type='submit'
											>
												Cập nhật
											</Button>
											<Button
												mt={4}
												colorScheme='blue'
												variant='outline'
												onClick={onClose2}
											>
												Thoát
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Menu>
	);
}
