export const columnsDataColumnsCustomer = [
    {
      Header: "Tên",
      accessor: "name",
    },
    {
      Header: "Số điện thoại",
      accessor: "phone_number",
    },
    {
      Header: "Địa chỉ",
      accessor: "address",
    },
    {
      Header: "Giới tính",
      accessor: "sex",
    },
];

export const columnsDataColumnsBill = [
  {
    Header: "Tên",
    accessor: "customer_username",
  },
  {
    Header: "Ngày tạo",
    accessor: "create_date",
    tyep: "date"
  },
  {
    Header: "Người tạo",
    accessor: "created_username",
  },
];