export function ConvertStr(intNum) {
    if (intNum > 9)
        return intNum.toString();
    return "0" + intNum.toString();
}
export function formatDate(dateString, notGetTime) {
    if (dateString) {
        try {
            const d = new Date(Date.parse(dateString));
            let dateStringFormated = ConvertStr(d.getDate()) + "/" + ConvertStr(d.getMonth() + 1) + "/" + d.getFullYear().toString()
            if (!notGetTime)
                dateStringFormated += " " + ConvertStr(d.getHours()) + ":" + ConvertStr(d.getMinutes());
            return dateStringFormated;
        } catch (error) {
            return dateString;
        }
    }
    return dateString;
}

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", symbolSign) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";
        const currencySymbol = symbolSign ? symbolSign : ""
        let amountConvert = amount.toString().replace(new RegExp(thousands, 'g'), "")
        let i = parseInt(amountConvert = Math.abs(Number(amountConvert) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return currencySymbol + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amountConvert - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        // console.log(e)
    }
};