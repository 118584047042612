export const columnsDataColumnsProduct = [
    {
      Header: "Tên",
      accessor: "name",
    },
    {
      Header: "Loại sản phẩm",
      accessor: "category_name",
    },
    {
      Header: "Mô tả",
      accessor: "detailed_description",
    },
    {
      Header: "Số lượng",
      accessor: "quantity",
    },
    {
      Header: "Giá",
      accessor: "cost",
    },
    {
      Header: "Ngày tạo",
      accessor: "create_date",
    },
    {
      Header: "Người tạo",
      accessor: "created_username",
    },
    {
      Header: "Ngày chỉnh sửa",
      accessor: "update_date",
    },
    {
      Header: "Ngưởi chỉnh sửa",
      accessor: "updated_username",
    }
];

export const columnsDataColumnsService = [
  {
    Header: "Tên",
    accessor: "name",
  },
  {
    Header: "Loại dịch vụ",
    accessor: "category_name",
  },
  {
    Header: "Mô tả",
    accessor: "detailed_description",
  },
  {
    Header: "Giá",
    accessor: "cost",
  },
  {
    Header: "Ngày tạo",
    accessor: "create_date",
  },
  {
    Header: "Người tạo",
    accessor: "created_username",
  },
  {
    Header: "Ngày chỉnh sửa",
    accessor: "update_date",
  },
  {
    Header: "Ngưởi chỉnh sửa",
    accessor: "updated_username",
  }
];

export const columnsDataColumnsCategory = [
  {
    Header: "Tên",
    accessor: "name",
  },
  {
    Header: "Mô tả",
    accessor: "description",
  },,
  {
    Header: "Ngày tạo",
    accessor: "create_date",
  },
  {
    Header: "Người tạo",
    accessor: "created_username",
  },
  {
    Header: "Ngày chỉnh sửa",
    accessor: "update_date",
  },
  {
    Header: "Ngưởi chỉnh sửa",
    accessor: "updated_username",
  }
];